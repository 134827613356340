import "../Home.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import YellowBadge from "../../../assets/website/yellow_badge.svg";
import HandleApply from "./HandleApply";

export const SubscriptionOptions = ({
    settings = null,
    setSettings = null,
    applyPage = false,
    setFailedSubmissionMessage = null,
    setPopupModal = null,
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [selectedTier, setSelectedTier] = useState(null);
    const tiers = [
        {
            tag: "FOR STARTERS",
            title: "Alia Platform",
            price: "$99/mo.",
            features: [
                "✓ 3 day free trial of the Alia Platform (afterwards it's $99/mo.)",
                "✓ Immediate application approval",
                "✓ Unlimited Platform access",
            ],
            plus: [],
        },
        {
            tag: "FOR EXTRA SUPPORT",
            title: "Alia Premium",
            price: "$175/mo.",
            features: [
                "✓ 3 day free trial of the Alia Platform (afterwards it's $175/mo.)",
                "✓ Application approval required*",
                "✓ Unlimited Platform access",
            ],
            plus: [
                "Plus:",
                "✓ 15 minute weekly check-ins with expert tutor",
                "✓ Development of a personalized study journey",
                "✓ Unlimited questions for our expert tutors via our chat window",
            ],
        },
        {
            tag: "FOR FASTEST RESULTS",
            title: "Alia Platinum",
            price: "$275/mo.",
            features: [
                "✓ 3 day free trial of the Alia Platform (afterwards it's $275/mo.)",
                "✓ Application approval required*",
                "✓ Unlimited Platform access",
            ],
            plus: [
                "Plus:",
                "✓ 45-minute weekly check-ins with an expert tutor",
                "✓ Development of a personalized study journey",
                "✓ Unlimited questions for our expert tutors via our chat window",
                "✓ Curation of additional study content, generated based on our " +
                    "personalized understanding of your knowledge gaps",
            ],
        },
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1200);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (selectedTier !== null) {
            setSettings({
                ...settings,
                subscription: tiers[selectedTier].title,
            });
        }
    }, [selectedTier]);

    return (
        <>
            <div
                className="info-boxes"
                style={{
                    width: "95%",
                    paddingBottom: applyPage ? "10px" : "40px",
                    flexDirection: isMobile ? "column" : "row",
                }}
            >
                {tiers.map((tier, index) => (
                    <div key={index} style={{}}>
                        <div
                            key={index}
                            className="info-box"
                            style={{
                                height: "fit-content",
                                position: "relative",
                                outline:
                                    applyPage &&
                                    selectedTier === index &&
                                    "6px solid #EC5725", // Using your orange color
                                cursor: applyPage ? "pointer" : "default",
                            }}
                            onClick={() => applyPage && setSelectedTier(index)}
                        >
                            {index === 1 && (
                                <div className="tier-popular-badge">
                                    <img
                                        src={YellowBadge}
                                        alt="Most Popular"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform:
                                                "translate(-50%, -50%) rotate(-30deg)",
                                            color: "#003169",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            width: "80%",
                                            lineHeight: "1.2",
                                            zIndex: 2,
                                        }}
                                    >
                                        MOST
                                        <br />
                                        POPULAR
                                    </div>
                                </div>
                            )}
                            <div
                                className="info-header"
                                style={{ zIndex: "3" }}
                            >
                                {tier.tag}
                            </div>
                            <div
                                className="tier-title"
                                style={{ textTransform: "uppercase" }}
                            >
                                {tier.title}
                            </div>
                            <div
                                className="tier-title"
                                style={{ fontSize: "24px" }}
                            >
                                {tier.price}
                            </div>
                            <div style={{ width: "90%", margin: "0 auto" }}>
                                <div
                                    className="info-text"
                                    style={{
                                        textAlign: "left",
                                        fontSize: "18px",
                                        paddingTop: "30px",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    {tier.features.map((feature, ix) => (
                                        <div key={ix}>{feature}</div>
                                    ))}
                                </div>
                                {tier.plus.length > 0 && (
                                    <div
                                        className="info-text"
                                        style={{
                                            textAlign: "left",
                                            fontSize: "18px",
                                            paddingTop: "15px",
                                            paddingBottom: "10px",
                                            color: "#EC5725",
                                        }}
                                    >
                                        {tier.plus.map((plus, ix) => (
                                            <div
                                                key={ix}
                                                style={
                                                    ix === 0
                                                        ? {
                                                              fontStyle:
                                                                  "italic",
                                                          }
                                                        : {}
                                                }
                                            >
                                                {plus}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        {applyPage && (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "60px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "20px",
                                    color: "#FFF",
                                }}
                            >
                                {index === selectedTier && <>✓ SELECTED</>}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div
                className="as-text"
                style={{
                    textAlign: "center",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    maxWidth: "1200px",
                    fontSize: "16px",
                }}
            >
                *The Alia Premium & Alia Platinum subscription tiers require a
                virtual interview for acceptance. On this call, we&apos;ll
                review your goals for the SAT, requirements of our intensive
                prep program, and whether we believe our approach aligns with
                your study methods. Once you submit your application, we&apos;ll
                send you an email to schedule an interview at your earliest
                convenience.
            </div>
            <div style={{ paddingTop: "50px" }}>
                <CheckoutButton
                    selectedTier={selectedTier}
                    settings={settings}
                    disabled={selectedTier === null && applyPage}
                    applyPage={applyPage}
                    setFailedSubmissionMessage={setFailedSubmissionMessage}
                    setPopupModal={setPopupModal}
                />
            </div>
        </>
    );
};

export const CheckoutButton = ({
    selectedTier,
    settings,
    disabled,
    applyPage,
    setFailedSubmissionMessage,
    setPopupModal,
}) => {
    const navigate = useNavigate();

    const handleCheckout = async () => {
        const targetPath = applyPage ? "/checkout" : "/apply";
        const userEmail = settings?.userEmail;

        if (applyPage && userEmail !== null) {
            const applyResult = await HandleApply(settings);

            if (!applyResult?.success) {
                setFailedSubmissionMessage(applyResult.message);
                setPopupModal(true);
                return;
            }
        }

        navigate(targetPath, {
            state: {
                ...(userEmail && { userEmail }),
                ...(selectedTier && { subscriptionTier: selectedTier }),
            },
        });
    };

    return (
        <button
            className={`apply-button ${disabled ? "disabled" : ""}`}
            onClick={handleCheckout}
            disabled={disabled}
            style={
                !disabled
                    ? {
                          backgroundColor: "#FFAB24",
                          color: "#003169",
                      }
                    : {}
            }
        >
            APPLY NOW
        </button>
    );
};

const Tiers = ({ settings = null, applyPage = false }) => {
    return (
        <div className="as-container" style={{ backgroundColor: "#016dea" }}>
            <div className="as-content">
                <div
                    className="as-title"
                    style={{ paddingBottom: "80px", color: "#FFF" }}
                >
                    Choose The Plan That Suits Your Needs
                </div>
                <SubscriptionOptions
                    settings={settings}
                    applyPage={applyPage}
                />
            </div>
        </div>
    );
};

export default Tiers;
