import React, { useEffect, useRef, useState } from "react";
import PromptDisplay from "./problem_components/PromptDisplay";
import QuestionDisplay from "./problem_components/QuestionDisplay";
import AnswerChoices from "./problem_components/AnswerChoices";
import NavigationButtons from "./problem_components/NavigationButtons";
import NavigationConsole from "./problem_components/NavigationConsole";
import "./ProblemWrapper.css";
import { useNavigate } from "react-router-dom";
import sendInfoToFlask from "../backend/SendInfoToFlask";
import MathJaxWrapper from "../utils/MathJaxWrapper";
import GenericAsync from "../backend/GenericAsync";
import NavConsoleConfirmModal from "../popups/NavConsoleConfirmModal";
import { useError } from "../common/ErrorContext";
import { useJourney } from "../common/JourneyContext";
import { useSettings } from "../common/SettingsContext";
import ConfirmModal from "../popups/ConfirmModal";
import { blockedUsers } from "../auth/BlockedUsers";

const ProblemWrapper = ({
    profile,
    currentModuleIndex,
    setCurrentModuleIndex,
    step,
    stepQuestions,
    stepName,
    stepType,
    stepStatus,
    modules,
    setModules,
    existingAnswers,
    setExistingAnswers,
    markForReview,
    setMarkForReview,
    timeRemaining,
    isDataLoading,
    setIsDataLoading,
    isSubmitting,
    setIsSubmitting,
    review = false,
}) => {
    const navigate = useNavigate();
    const [isNavConsoleVisible, setIsNavConsoleVisible] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const numericInputRef = React.createRef();
    const { settings } = useSettings();

    const [selectedAnswers, setSelectedAnswers] = useState({}); // This will store new answers for all questions.
    const [isAnswerViewActive, setIsAnswerViewActive] = useState(false);
    const [strikeThroughMode, setStrikeThroughMode] = useState(false);
    const [viewAnswerMode, setViewAnswerMode] = useState(false);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

    const { refreshJourney } = useJourney();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const isFirstRender = useRef(true);
    const { triggerError } = useError();

    // Define handleModalConfirm and handleModalClose functions
    const handleModalConfirm = () => {
        setIsReviewModalOpen(false);
        handleSubmit(); // Assuming handleSubmit is the function to submit the review
    };

    const handleTimeoutNextModule = () => {
        setShowTimeoutModal(false); // Finally, hide the popup
        handleSubmit();
    };

    useEffect(() => {
        // Assuming selectedAnswers is an array and currentQuestionIndex is the index of the current question
        const answer = selectedAnswers[currentQuestionIndex];
        if (numericInputRef.current && answer !== undefined) {
            numericInputRef.current.value = answer;
        } else if (numericInputRef.current) {
            numericInputRef.current.value = null;
        }
    }, [selectedAnswers, currentQuestionIndex]);

    // Logic to show the popup when time runs out
    useEffect(() => {
        if (timeRemaining === 0 && !isDataLoading) {
            setShowTimeoutModal(true);
        }
    }, [timeRemaining, isDataLoading]);

    // Send navigation info to Flask when stepQuestions, currentModuleIndex, or currentQuestionIndex is changed
    useEffect(() => {
        if (isFirstRender.current) {
            // Will have to change this later to keep track of time on 1st problems
            // Maybe load directly from FetchTime
            timeRemaining = null;
        }

        console.log("Question ID", questionId);

        if (!profile.tutorMode || !review) {
            sendInfoToFlask({
                backendUrl: backendUrl,
                userEmail: profile.email,
                step: step,
                module: currentModule,
                questionIndex: currentQuestionIndex,
                questionId: questionId,
                questionType: questionType,
                timeRemaining: timeRemaining,
                action: "navigate_to_page",
            });
        }
    }, [stepQuestions, currentModuleIndex, currentQuestionIndex, profile]); // Add relevant dependencies

    // Update selectedAnswers dictionary
    useEffect(() => {
        // Skip the effect on the first render
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        // Get the questionId for the current question
        const questionData =
            stepQuestions[modules[currentModuleIndex]][currentQuestionIndex];
        const questionId = questionData["question_id"];
        if (!questionData) {
            return;
        }

        let multipleChoiceAnswer;
        let numericAnswer;

        if (selectedAnswers[currentQuestionIndex] !== undefined) {
            if (questionData["question_format"] === "radio") {
                multipleChoiceAnswer = selectedAnswers[currentQuestionIndex];
                numericAnswer = null;
            } else {
                multipleChoiceAnswer = null;
                if (selectedAnswers[currentQuestionIndex] !== "") {
                    numericAnswer = selectedAnswers[currentQuestionIndex];
                } else {
                    numericAnswer = null;
                }
            }
        } else {
            // Handle the case when an answer is deselected
            multipleChoiceAnswer = null;
            numericAnswer = null;
        }

        // Perform the network request
        if (!profile.tutorMode || !review) {
            sendInfoToFlask({
                backendUrl: backendUrl,
                userEmail: profile.email,
                step: step,
                module: currentModule,
                questionIndex: currentQuestionIndex,
                questionId: questionId,
                questionType: questionType,
                multipleChoiceAnswer: multipleChoiceAnswer,
                numericAnswer: numericAnswer,
                timeRemaining: timeRemaining,
                action: "select_answer",
            });
        }
    }, [selectedAnswers, stepQuestions, profile]);

    const handleSubmit = async () => {
        // Send submission info to Flask
        isFirstRender.current = true;

        // Function to remove a module based on the second character
        const removeModuleBySecondChar = (input) => {
            const inputStr = String(input); // Ensure the input is treated as a string
            // Filter out the module whose second character matches the input
            const updatedModules = modules.filter(
                (module) => module[1] !== inputStr,
            );
            setModules(updatedModules); // Update the state with the filtered list
        };

        if (currentModuleIndex === modules.length - 1) {
            if (!profile.tutorMode || !review) {
                sendInfoToFlask({
                    backendUrl: backendUrl,
                    userEmail: profile.email,
                    step: step,
                    module: currentModule,
                    timeRemaining: timeRemaining,
                    action: "submit_step",
                });

                setIsDataLoading(true);
                setIsSubmitting(true);

                try {
                    await GenericAsync({
                        backendUrl: `${backendUrl}/api/compute-step-results`,
                        dataToSend: {
                            userEmail: profile.email,
                            step: step,
                            stepName: stepName,
                            stepQuestions: stepQuestions,
                            stepStatus: stepStatus,
                            stepType: stepType,
                            modules: modules,
                            timeRemaining: timeRemaining,
                            timeZone:
                                Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                        },
                    });
                    // Check the response to see if the background task was initiated
                    refreshJourney();
                    setIsSubmitting(false);
                    navigate("/", { state: { refreshJourney: true } });
                } catch (error) {
                    triggerError(error.message);
                }
            } else {
                navigate("/");
            }
        } else {
            if (
                currentModuleIndex !== 1 &&
                stepType === "tests" &&
                stepStatus !== "revising"
            ) {
                setIsDataLoading(true);
                // Compute next module difficulty
                try {
                    const result = await GenericAsync({
                        backendUrl: `${backendUrl}/api/compute-next-module-difficulty`,
                        dataToSend: {
                            userEmail: profile.email,
                            step: step,
                            module: modules[currentModuleIndex],
                        },
                    });
                    const next_difficulty = result.next_module_difficulty; // foundations or advanced

                    // Determine which character to remove based on next_difficulty and currentModuleIndex
                    const charToRemoveMap = {
                        foundations: currentModuleIndex === 0 ? 3 : 6,
                        advanced: currentModuleIndex === 0 ? 1 : 4,
                    };

                    // Use the mapped values to determine which character to remove
                    const charToRemove = charToRemoveMap[next_difficulty];

                    removeModuleBySecondChar(charToRemove);
                    proceedToNextModule();
                } catch (error) {
                    triggerError(error.message);
                }
                setIsDataLoading(false);
            } else {
                proceedToNextModule();
            }
        }
    };

    const proceedToNextModule = () => {
        setSelectedAnswers({}); // Reset for the new module
        setExistingAnswers({});
        setMarkForReview({});
        setCurrentQuestionIndex(0);
        setCurrentModuleIndex(currentModuleIndex + 1);

        if (!profile.tutorMode || !review) {
            sendInfoToFlask({
                backendUrl: backendUrl,
                userEmail: profile.email,
                step: step,
                questionId: "null",
                module: currentModule,
                timeRemaining: timeRemaining,
                action: "submit_module",
            });
        }

        // Catch for empty stepQuestions dictionary
        if (currentModuleIndex > 5) {
            navigate("/", { state: { refreshJourney: true } });
        }
    };

    const handleAnswerSelect = (choiceIndex) => {
        setSelectedAnswers((selectedDict) => {
            const updatedSelected = { ...selectedDict };
            let shouldDeselect = false; // Variable to determine deselection

            // Check if currentQuestionIndex exists in existingAnswers
            setExistingAnswers((existing) => {
                const updatedExisting = { ...existing };
                if (currentQuestionIndex in updatedExisting) {
                    delete updatedExisting[currentQuestionIndex];
                    if (existing[currentQuestionIndex] === choiceIndex) {
                        shouldDeselect = true;
                    }
                }
                return updatedExisting;
            });

            // If the same choice is already selected, deselect it
            if (updatedSelected[currentQuestionIndex] === choiceIndex) {
                delete updatedSelected[currentQuestionIndex];
            }
            // Otherwise, select the new choice
            else if (!shouldDeselect) {
                updatedSelected[currentQuestionIndex] = choiceIndex;
            }

            // Return updated state
            return updatedSelected;
        });
    };

    const handleMarkForReview = () => {
        const questionId =
            stepQuestions[modules[currentModuleIndex]][currentQuestionIndex][
                "question_id"
            ];

        // Determine the action first
        let unmark = currentQuestionIndex in markForReview;

        // Update the state
        setMarkForReview((prev) => {
            const updatedMarked = { ...prev };

            if (unmark) {
                delete updatedMarked[currentQuestionIndex];
            } else {
                updatedMarked[currentQuestionIndex] = true;
            }

            return updatedMarked;
        });

        // Determine the action string
        let action = unmark ? "unmark_review" : "mark_for_review";

        if (!profile.tutorMode || !review) {
            sendInfoToFlask({
                backendUrl: backendUrl,
                userEmail: profile.email,
                step: step,
                module: currentModule,
                questionIndex: currentQuestionIndex,
                questionId: questionId,
                questionType: questionType,
                action: action,
            });
        }
    };

    const handleNavigation = (newIndex) => {
        // Reset the isAnswerViewActive state when navigating to a new question
        setIsAnswerViewActive(false);

        // Set the updated question index
        setCurrentQuestionIndex(newIndex);
    };

    if (isSubmitting) {
        return (
            <div>
                {stepName} completed! Now processing your submission and loading
                the home page...
            </div>
        );
    }

    const currentModule = modules[currentModuleIndex];
    const currentQuestion = stepQuestions[currentModule][currentQuestionIndex];
    const combinedAnswers = { ...existingAnswers, ...selectedAnswers };
    const navBarIndices = stepQuestions[modules[currentModuleIndex]].map(
        (i) => i["question_index"],
    );
    const questionType = currentQuestion["question_class"];
    const questionId = currentQuestion["question_id"];

    if (!currentQuestion) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {showTimeoutModal && (
                <div className="modal-backdrop">
                    <div className="modal-content">
                        <p>The time for this module has expired!</p>
                        <button onClick={() => setShowTimeoutModal(false)}>
                            Continue with this module
                        </button>
                        <button onClick={handleTimeoutNextModule}>
                            Take me to the next module
                        </button>
                    </div>
                </div>
            )}
            <div
                className={
                    isAnswerViewActive
                        ? "test-wrapper expanded"
                        : "test-wrapper"
                }
            >
                <div>
                    <PromptDisplay
                        prompt={currentQuestion.prompt}
                        currentQuestionIndex={
                            navBarIndices[currentQuestionIndex]
                        }
                        imageData={currentQuestion.image_url}
                        markForReview={markForReview[currentQuestionIndex]}
                        onMarkForReview={handleMarkForReview}
                        strikeThroughMode={strikeThroughMode}
                        setStrikeThroughMode={setStrikeThroughMode}
                        viewAnswerMode={viewAnswerMode}
                        setViewAnswerMode={setViewAnswerMode}
                        tutorMode={profile.tutorMode}
                        stepStatus={stepStatus}
                    />
                    <QuestionDisplay question={currentQuestion.question} />
                    <AnswerChoices
                        choices={currentQuestion.choices}
                        explanations={currentQuestion.explanations}
                        selectedAnswer={combinedAnswers[currentQuestionIndex]}
                        questionFormat={currentQuestion.question_format}
                        onSelectAnswer={handleAnswerSelect}
                        strikeThroughMode={strikeThroughMode}
                        viewAnswerMode={viewAnswerMode}
                        profile={profile}
                        step={step}
                        module={modules[currentModuleIndex]}
                        questionIndex={currentQuestionIndex}
                        questionId={currentQuestion.question_id}
                        questionType={questionType}
                        timeRemaining={timeRemaining}
                        correctRadioAnswer={
                            currentQuestion.correct_radio_answer
                        }
                        correctNumericAnswer={
                            currentQuestion.correct_numeric_answer
                        }
                    />
                </div>
                {isAnswerViewActive && (
                    <div className="column answerBox">
                        <MathJaxWrapper>
                            {
                                stepQuestions[modules[currentModuleIndex]][
                                    currentQuestionIndex
                                ]["explanation"]
                            }
                        </MathJaxWrapper>
                    </div>
                )}
            </div>
            <div className="bottom-section">
                <div className="bottom-container">
                    <div className="profile-name">
                        <div className="profile-name">
                            {profile.tutorMode ? "Tutor Mode: " : ""}
                            {settings.userFirstName} {settings.userLastName}
                        </div>
                    </div>
                    <div className="dummy-container"></div>
                    {!isReviewModalOpen && (
                        <NavigationConsole
                            problemMode={stepType}
                            navBarIndices={navBarIndices}
                            currentQuestion={currentQuestionIndex}
                            onSelectQuestion={handleNavigation}
                            isVisible={isNavConsoleVisible}
                            toggleVisibility={() =>
                                setIsNavConsoleVisible(!isNavConsoleVisible)
                            }
                            answeredQuestions={
                                new Set(
                                    Object.keys(combinedAnswers).filter(
                                        (index) =>
                                            combinedAnswers[index] !== null,
                                    ),
                                )
                            }
                            markForReview={
                                new Set(
                                    Object.keys(markForReview).filter(
                                        (index) =>
                                            markForReview[index] !== null,
                                    ),
                                )
                            }
                            onReviewPageToggle={() =>
                                setIsReviewModalOpen(true)
                            } // Pass this prop here
                        />
                    )}
                    <NavConsoleConfirmModal
                        isOpen={isReviewModalOpen}
                        onConfirm={handleModalConfirm}
                        onClose={() => setIsReviewModalOpen(false)}
                        message="Are you sure you want to submit your answers?"
                        navConsole={true}
                        confirmMessage="Submit"
                        declineMessage="Cancel"
                    >
                        <NavigationConsole
                            inModal={true}
                            problemMode={stepType}
                            navBarIndices={navBarIndices}
                            currentQuestion={currentQuestionIndex}
                            onSelectQuestion={handleNavigation}
                            isVisible={true}
                            toggleVisibility={() =>
                                setIsNavConsoleVisible(!isNavConsoleVisible)
                            }
                            answeredQuestions={
                                new Set(
                                    Object.keys(combinedAnswers).filter(
                                        (index) =>
                                            combinedAnswers[index] !== null,
                                    ),
                                )
                            }
                            markForReview={
                                new Set(
                                    Object.keys(markForReview).filter(
                                        (index) =>
                                            markForReview[index] !== null,
                                    ),
                                )
                            }
                        />
                    </NavConsoleConfirmModal>
                    <NavigationButtons
                        currentQuestionIndex={currentQuestionIndex}
                        totalQuestions={stepQuestions[currentModule].length}
                        onNavigate={handleNavigation}
                        onSubmit={() => setIsReviewModalOpen(true)}
                        review={review}
                    />
                    <ConfirmModal
                        isOpen={
                            (!isDataLoading && !settings?.subscriptionActive) ||
                            blockedUsers.includes(profile.email)
                        }
                        onConfirm={() => navigate("/")}
                        onClose={() => {
                            window.location.href =
                                "https://aliaeducation.com/apply";
                        }}
                        message={
                            <div
                                style={{
                                    textAlign: "center",
                                    lineHeight: "36px",
                                    fontSize: "18px",
                                }}
                            >
                                <strong>
                                    Oh no! You do not have access to this
                                    material.
                                </strong>{" "}
                                <br />
                                For full access to our custom test preparation
                                platform, you need an active Alia account.{" "}
                                <br />
                                Renew your Alia subscription now to continue
                                your SAT journey! <br />
                            </div>
                        }
                        confirmMessage={
                            <div style={{ fontSize: "18px" }}>Go Back</div>
                        }
                        declineMessage={
                            <div style={{ fontSize: "18px" }}>Renew</div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ProblemWrapper;
