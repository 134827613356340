import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "./Arrow";

const Reviews = ({ pricing = false }) => {
    const navigate = useNavigate();
    const [reviewIndex, setReviewIndex] = useState(0);
    const reviews = [
        {
            text: "“I took the SAT twice last year and got a 1410 then 1420. I was feeling pretty stuck and didn’t know how to get my score to move. Alia made it simple for me to practice the harder concepts I needed to master for the test - it was the most similar to what I saw on test day. I just hit a 1520 and now I can focus on my college apps :). You have to put in the work, but they make everything else so easy.”",
            author: "Sarah M.",
        },
    ];

    const incrementIndex = () => {
        setReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const decrementIndex = () => {
        setReviewIndex(
            (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length,
        );
    };

    return (
        <div
            className="as-container"
            style={{ backgroundColor: pricing ? "#FFF" : "#016dea" }}
        >
            <div className="as-content">
                <div style={{ paddingBottom: "50px" }}>
                    <svg width="155" height="31">
                        <use href="#stars" x="0" />
                    </svg>
                </div>
                <div className="review-middle">
                    <div className="review-arrow-container hide-on-mobile-800">
                        <Arrow
                            direction="left"
                            onClick={() => decrementIndex()}
                            className="hide-on-web-800"
                            style={{ display: "none" }}
                        />
                    </div>
                    <div className="review-middle-text">
                        <div
                            className="as-title"
                            style={{ color: pricing && "#016dea" }}
                        >
                            Don&apos;t Just Take Our Word For It
                        </div>
                        <div
                            className="as-text"
                            style={{
                                paddingBottom: "40px",
                                color: pricing && "#016dea",
                                // textAlign: "justify",
                                width: "100%",
                            }}
                        >
                            {reviews[reviewIndex].text}
                        </div>
                        <div
                            className="as-text"
                            style={{
                                color: pricing && "#016dea",
                                paddingBottom: "0px",
                                width: "100%",
                            }}
                        >
                            — {reviews[reviewIndex].author}
                        </div>
                    </div>
                    <div className="review-arrow-container">
                        <Arrow
                            direction="left"
                            onClick={() => decrementIndex()}
                            className="hide-on-web-800"
                            style={{ display: "none" }}
                        />
                        <Arrow
                            direction="right"
                            onClick={() => incrementIndex()}
                            className="hide-on-web-800"
                            style={{ display: "none" }}
                        />
                    </div>
                </div>
                <button
                    className="apply-button"
                    style={{
                        color: !pricing && "#003169",
                        backgroundColor: !pricing && "#FFAB24",
                    }}
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Reviews;
