// IntercomUtils.js
export const updateIntercomUser = (settings) => {
    if (window.Intercom) {
        window.Intercom("update", {
            app_id: "l2013q8g",
            name: `${settings.userFirstName} ${settings.userLastName}`,
            email: settings.userEmail,
            phone: settings.phoneNumber,
        });
    }
};

export const updateIntercomVisibility = (visible) => {
    if (window.Intercom) {
        if (visible) {
            window.Intercom("update", { hide_default_launcher: false });
        } else {
            window.Intercom("update", { hide_default_launcher: true });
        }
    }
};
