// src/components/ProblemPage.js
import React, { useState, useEffect } from "react";
import ProblemWrapper from "./ProblemWrapper";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useParams } from "react-router-dom";
import Clock from "./header/Clock"; // Import Clock component
import Calculator from "./header/Calculator"; // Import Calculator component
import "./ProblemPage.css"; // Import the CSS file for styling
import ReferenceSheetButton from "./header/ReferenceSheetButton";
import { useLocation } from "react-router-dom";
import GenericAsync from "../backend/GenericAsync";
import { useUserProfile } from "../auth/UserProfileContext";
import { useError } from "../common/ErrorContext";

const SaveExitButton = () => {
    const navigate = useNavigate();
    const handleSaveAndExit = () => {
        navigate("/"); // Navigate to the Home Page
    };

    return (
        <button onClick={handleSaveAndExit} className={"header-button"}>
            Save/Exit
        </button>
    );
};

const ProblemPage = () => {
    const navigate = useNavigate();
    const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
    const { stepType } = useParams();
    const [clockTime, setClockTime] = useState(null); // Add this state for time
    const [isDataLoading, setIsDataLoading] = useState(true); // Initialize as true to assume loading initially
    const [initialTimes, setInitialTimes] = useState(null); // Initialize as true to assume loading initially
    const [existingTimes, setExistingTimes] = useState({});
    const [stepQuestions, setStepQuestions] = useState(null);
    const [modules, setModules] = useState(null);
    const [sections, setSections] = useState(null);
    const [isComplete, setIsComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [existingAnswers, setExistingAnswers] = useState({}); // This will store current answers for all questions.
    const [markForReview, setMarkForReview] = useState({});

    const location = useLocation();
    const { activeStep } = location.state || {};
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { profile } = useUserProfile();
    const { triggerError } = useError();

    useEffect(() => {
        if (!profile.email || !modules) {
            return;
        }

        function getModuleName(module) {
            if (module.includes("1") || module.includes("3")) {
                return "Module 2: Reading and Writing";
            } else if (module.includes("2")) {
                return "Module 1: Reading and Writing";
            } else if (module.includes("4") || module.includes("6")) {
                return "Module 2: Math";
            } else if (module.includes("5")) {
                return "Module 1: Math";
            }
        }

        let moduleNames = [""];
        if (stepType === "tests") {
            moduleNames = modules.map((module) => getModuleName(module));
        }

        if (activeStep.status === "revising") {
            if (stepType === "tests") {
                moduleNames = moduleNames.map((module) => `${module}, `);
            }
            moduleNames = moduleNames.map((module) => `${module}Revisions`);
        }

        setSections(moduleNames);
    }, [profile, modules]);

    function sortModules(strings) {
        const order = [2, 1, 3, 5, 4, 6];

        return strings.sort((a, b) => {
            if (a.length !== 2 || b.length !== 2) {
                return a.length - b.length || a.localeCompare(b);
            }

            const numA = parseInt(a[1]);
            const numB = parseInt(b[1]);

            if (numA >= 1 && numA <= 6 && numB >= 1 && numB <= 6) {
                return (
                    order.indexOf(numA) - order.indexOf(numB) ||
                    a[0].localeCompare(b[0])
                );
            }

            return numA - numB || a.localeCompare(b);
        });
    }

    const fetchActions = async (modules) => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/fetch-actions`,
                dataToSend: {
                    userEmail: profile.email,
                    step: activeStep.step,
                    actionFilters: ["select_answer"],
                    problemMode: stepType,
                    modules: modules,
                },
            });

            const latestTimes = {};

            // Iterate over the response to populate latestTimes object
            response.forEach((entry) => {
                // Check if this module's entry is newer than what we have (if we have one)
                if (
                    entry.time_remaining != null &&
                    (!latestTimes[entry.module] ||
                        latestTimes[entry.module].time_remaining >
                            entry.time_remaining)
                ) {
                    latestTimes[entry.module] = {
                        time_remaining: entry.time_remaining,
                        timestamp: entry.submit_time,
                    };
                }
            });

            return latestTimes;
        } catch (error) {
            triggerError(error.message);
        }
    };

    const fetchQuestions = async () => {
        const backend_fetch = `${backendUrl}/api/get-questions`;
        try {
            const response = await GenericAsync({
                backendUrl: backend_fetch,
                dataToSend: {
                    backendUrl: backend_fetch,
                    userEmail: profile.email,
                    step: activeStep.step,
                    stepType: stepType.replaceAll("-", "_"),
                    review: activeStep?.review || false,
                },
            });
            return response;
        } catch (error) {
            triggerError(error.message);
        }
    };

    useEffect(() => {
        if (isComplete) {
            const timer = setTimeout(() => {
                navigate("/");
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isComplete]);

    // Fetch step questions and actions for timing
    useEffect(() => {
        if (!profile.email) {
            return;
        }

        setIsDataLoading(true);

        const fetchQuestionsAsync = async () => {
            try {
                const response = await fetchQuestions();
                setStepQuestions(response);
                const moduleKeys = Object.keys(response);
                setModules(sortModules(moduleKeys));

                if (moduleKeys.length === 0) {
                    setIsComplete(true);
                }
            } catch (error) {
                triggerError(error.message);
            }
        };

        const fetchAllActions = async () => {
            if (stepType === "tests" || stepType === "modules") {
                let timeResults = null;
                try {
                    const _modules = activeStep.assigned_modules.split("-");
                    timeResults = await fetchActions(_modules);
                } catch (error) {
                    triggerError(error.message);
                } finally {
                    if (timeResults) {
                        setExistingTimes(timeResults);
                    }
                }
            }
        };

        const performFetches = async () => {
            try {
                await Promise.all([fetchQuestionsAsync(), fetchAllActions()]);
            } catch (error) {
                triggerError(error.message);
            } finally {
                setIsDataLoading(false);
            }
        };
        performFetches();

        // Send info to Flask about starting the test -- implement later
        // if (!profile.tutorMode) {
        //     sendInfoToFlask({
        //         backendUrl: backendUrl,
        //         userEmail: profile.email,
        //         step: activeStep.step,
        //         module: modules[currentModuleIndex],
        //         action: "start_step",
        //     });
        // }
    }, [profile]); // Dependency array with step to refetch if step changes

    useEffect(() => {
        if (!stepQuestions || !modules) {
            return;
        }

        const fetchActions = async () => {
            try {
                const response = await GenericAsync({
                    backendUrl: `${backendUrl}/api/fetch-actions`,
                    dataToSend: {
                        userEmail: profile.email,
                        step: activeStep.step,
                        actionFilters: [
                            "mark_for_review",
                            "unmark_review",
                            "select_answer",
                            "navigate_to_page",
                        ],
                        problemMode: stepType,
                        modules: [modules[currentModuleIndex]],
                    },
                });
                let _existingMarks = {};
                let _existingAnswers = {};
                const questionIds = stepQuestions[
                    modules[currentModuleIndex]
                ].map((i) => i["question_id"]);

                response.forEach((action) => {
                    if (questionIds.includes(action.question_id)) {
                        if (
                            action.module === modules[currentModuleIndex] ||
                            activeStep.status === "revising"
                        ) {
                            if (action.action === "mark_for_review") {
                                _existingMarks[action.question_index] = true;
                            } else if (action.action === "select_answer") {
                                _existingAnswers[action.question_index] =
                                    action.multiple_choice_answer ||
                                    action.numeric_answer;
                            }
                        }
                    }
                });
                setMarkForReview(_existingMarks);
                setExistingAnswers(_existingAnswers);
            } catch (error) {
                triggerError(error.message);
            }
        };
        fetchActions();
    }, [stepQuestions, currentModuleIndex, profile]); //

    useEffect(() => {
        if (activeStep.time_multiplier !== null) {
            let times = [];
            if (stepType === "tests") {
                times = [32 * 60, 32 * 60, 35 * 60, 35 * 60];
            } else if (stepType === "modules") {
                times = [
                    activeStep.step_name.includes("Reading")
                        ? 32 * 60
                        : 35 * 60,
                ];
            }
            times = times.map(
                (time) => (time *= parseFloat(activeStep.time_multiplier)),
            );
            setInitialTimes(times);
        }
    }, [profile]);

    useEffect(() => {
        if (!modules) {
            return;
        }

        let clockTime = null;
        if (existingTimes && existingTimes[modules[currentModuleIndex]]) {
            const existingTime =
                existingTimes[modules[currentModuleIndex]].time_remaining;
            if (Number.isInteger(existingTime)) {
                clockTime = existingTime;
            }
        } else if (initialTimes && initialTimes[currentModuleIndex]) {
            clockTime = initialTimes[currentModuleIndex];
        }
        setClockTime(clockTime);
    }, [modules, existingTimes, initialTimes, currentModuleIndex]);

    const renderContent = () => {
        if (isComplete) {
            return (
                <div>
                    {activeStep.step_name} is complete! Navigating back home.
                </div>
            );
        }

        if (isSubmitting) {
            return (
                <div>
                    {activeStep.step_name} completed! Now processing your
                    submission and loading the home page...
                </div>
            );
        }

        return isDataLoading ? (
            <div>Loading...</div>
        ) : (
            <ProblemWrapper
                profile={profile}
                currentModuleIndex={currentModuleIndex}
                setCurrentModuleIndex={setCurrentModuleIndex}
                step={activeStep.step}
                stepQuestions={stepQuestions}
                stepName={activeStep.step_name}
                stepType={stepType}
                stepStatus={activeStep.status}
                timeRemaining={clockTime}
                modules={modules}
                setModules={setModules}
                existingAnswers={existingAnswers}
                setExistingAnswers={setExistingAnswers}
                markForReview={markForReview}
                setMarkForReview={setMarkForReview}
                isDataLoading={isDataLoading}
                setIsDataLoading={setIsDataLoading}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                review={activeStep?.review}
            />
        );
    };

    const renderClock = () => {
        const shouldRenderClock =
            !isDataLoading &&
            (stepType === "tests" || (stepType === "modules" && clockTime)) &&
            activeStep.status !== "revising";

        return shouldRenderClock ? (
            <Clock clockTime={clockTime} setClockTime={setClockTime} />
        ) : null;
    };

    return (
        <div className="test-page">
            <div className="top-bar">
                <div className="module-name">
                    <div>{activeStep.step_name}</div>
                    {sections && sections.length > currentModuleIndex && (
                        <div>{sections[currentModuleIndex]}</div>
                    )}
                </div>
                <div className="clock-container">{renderClock()}</div>
                <div className="top-right-tools">
                    <Calculator />
                    <ReferenceSheetButton />
                    <SaveExitButton />
                </div>
            </div>
            {renderContent()}
        </div>
    );
};

export default ProblemPage;
