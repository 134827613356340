import "./Modal.css";
import React from "react";
import { returnFormattedProblem } from "../utils/TextRender";
import MathJaxWrapper from "../../shared/utils/MathJaxWrapper";

const DraftQuestionModal = ({ isOpen, draftProblems, problemIx, onClose }) => {
    if (!isOpen) return;

    function renderDraftProblem() {
        if (!(problemIx in draftProblems)) {
            return null;
        } else {
            return (
                <MathJaxWrapper>
                    {returnFormattedProblem(draftProblems[problemIx], true)}
                </MathJaxWrapper>
            );
        }
    }

    return (
        <div className="modal-backdrop">
            <div
                className="modal-content"
                style={{
                    fontFamily: "none",
                }}
            >
                {renderDraftProblem()}
                <div className="modal-actions center-actions">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default DraftQuestionModal;
