// SettingsContext.js
import { createContext, useContext, useEffect, useState } from "react";
import GenericAsync from "../backend/GenericAsync";
import { useUserProfile } from "../auth/UserProfileContext";
import { useLoginContext } from "../auth/LoginContext";
import SettingsModal from "../popups/SettingsModal";
import { useLocation } from "react-router-dom";

const SettingsContext = createContext(null);

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState(null);
    const [settingsSaveAbility, setSettingsSaveAbility] = useState(null);
    const { loginAbility } = useLoginContext();
    const location = useLocation();
    const { profile, studentDict, setStudentDict } = useUserProfile();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [settingsLoading, setSettingsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriptionBannerShown, setSubscriptionBannerShown] =
        useState(false);

    const APP_VERSION = process.env.REACT_APP_VERSION;

    const getSettings = async () => {
        setSettingsLoading(true);

        const cookies = document.cookie.split("; ");
        const fbpCookie = cookies.find((cookie) => cookie.startsWith("_fbp="));
        const fbcCookie = cookies.find((cookie) => cookie.startsWith("_fbc="));

        // Get the values of the cookies
        const fbp = fbpCookie ? fbpCookie.split("=")[1] : null;
        const fbc = fbcCookie ? fbcCookie.split("=")[1] : null;

        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-user-info`,
                dataToSend: {
                    userEmail: profile.email,
                    fbp: fbp,
                    fbc: fbc,
                },
            });
            setSettings(response);
        } catch (error) {
            console.log(error.message);
        }
        setSettingsLoading(false);
    };

    const saveSettings = async () => {
        try {
            await GenericAsync({
                backendUrl: `${backendUrl}/api/update-user-info`,
                dataToSend: {
                    userEmail: profile.email,
                    settings: settings,
                },
            });
            setSettings({ ...settings });
        } catch (error) {
            console.log(error.message);
        }
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (profile.email && loginAbility) {
            getSettings();
        } else {
            setSettingsLoading(false);
        }
    }, [profile.email, loginAbility]);

    useEffect(() => {
        if (!settings || settingsSaveAbility === null) return;

        if (!settingsSaveAbility && location.pathname !== "/settings") {
            setIsModalOpen(true);
        }
    }, [settingsSaveAbility, location.pathname]);

    useEffect(() => {
        if (!settings) return;
        if (settings?.subscriptionBanner) {
            setSubscriptionBannerShown(true);
        } else {
            setSubscriptionBannerShown(false);
        }
    }, [settings?.subscriptionBanner, settings?.userEmail]);

    useEffect(() => {
        if (settingsLoading) return;

        const requiredSettings = [
            settings?.userFirstName,
            settings?.userLastName,
            settings?.focus,
            settings?.testDate,
        ];

        if (APP_VERSION === "SAT") {
            requiredSettings.push(settings?.goalScore);
        }

        const allSettingsDefined = requiredSettings.every(
            (setting) =>
                setting !== undefined &&
                setting !== null &&
                setting !== "" &&
                setting !== "none",
        );
        setSettingsSaveAbility(allSettingsDefined);
    }, [settings]);

    return (
        <SettingsContext.Provider
            value={{
                settings,
                setSettings,
                settingsSaveAbility,
                settingsLoading,
                saveSettings,
                subscriptionBannerShown,
                setSubscriptionBannerShown,
                isModalOpen,
                setIsModalOpen,
            }}
        >
            {children}
            {!settingsLoading && settings && (
                <SettingsModal
                    settings={settings}
                    setSettings={setSettings}
                    settingsSaveAbility={settingsSaveAbility}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    saveSettings={saveSettings}
                    studentDict={studentDict}
                    setStudentDict={setStudentDict}
                />
            )}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => useContext(SettingsContext);
