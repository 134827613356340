import React from "react";

const Arrow = ({ direction, onClick, className, style }) => {
    return (
        <svg
            width="50"
            height="30"
            viewBox="0 0 50 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className={`review-arrow ${className}`}
            style={{
                transform: direction === "right" ? "rotate(180deg)" : "none",
                ...style,
            }}
        >
            <path
                d="M0.585785 13.5858C-0.195259 14.3668 -0.195259 15.6332 0.585785 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857859C15.3611 0.0768105 14.0948 0.0768104 13.3137 0.857859L0.585785 13.5858ZM74 13L2 13L2 17L74 17L74 13Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Arrow;
