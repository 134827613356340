import queryString from "query-string";
import GenericAsync from "../../shared/backend/GenericAsync";
import triggerEventing from "../../shared/advertising/Eventing";

const HandleApply = async (settings) => {
    let response = null;

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const eventId = crypto.randomUUID();
    const cookies = document.cookie.split("; ");
    const fbpCookie = cookies.find((cookie) => cookie.startsWith("_fbp="));
    const fbcCookie = cookies.find((cookie) => cookie.startsWith("_fbc="));
    const ttclidCookie = cookies.find((cookie) => cookie.startsWith("ttclid="));
    const ttpCookie = cookies.find((cookie) => cookie.startsWith("_ttp="));

    // Get the values of the cookies
    const fbp = fbpCookie ? fbpCookie.split("=")[1] : null;
    const fbc = fbcCookie ? fbcCookie.split("=")[1] : null;
    const ttclid = ttclidCookie ? ttclidCookie.split("=")[1] : null;
    const ttp = ttpCookie ? ttpCookie.split("=")[1] : null;

    // Get UTM parameters and other query parameters
    const queryParams = queryString.parse(window.location.search);
    const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
        fbclid,
        gclid,
        ...otherParams
    } = queryParams;

    try {
        response = await GenericAsync({
            backendUrl: `${backendUrl}/api/apply-for-account`,
            dataToSend: {
                eventId: eventId,
                // Facebook parameters
                fbc: fbc || null,
                fbp: fbp || null,
                fbclid: fbclid || null,
                // TikTok parameters
                ttclid: ttclid || null,
                ttp: ttp || null,
                // Google parameter
                gclid: gclid || null,
                // UTM parameters
                utm_source: utm_source || null,
                utm_medium: utm_medium || null,
                utm_campaign: utm_campaign || null,
                utm_content: utm_content || null,
                utm_term: utm_term || null,
                // Other data
                otherParams,
                settings,
                referrer: document.referrer || null,
                userAgent: navigator.userAgent || null,
            },
        });
        if (response.success) {
            try {
                triggerEventing({
                    userEmail: settings?.userEmail,
                    eventName: "AddToCart",
                    eventId: eventId,
                });
            } catch (error) {
                console.error(
                    "An error occurred during eventing:",
                    error.message,
                );
            }
        }
        return response;
    } catch (error) {
        console.error("An error occurred during application:", error.message);
        return error.message;
    }
};

export default HandleApply;
