import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import GenericAsync from "../../shared/backend/GenericAsync";
import MathJaxWrapper from "../../shared/utils/MathJaxWrapper";
import NavigationConsole from "./NavigationConsole";
import { returnFormattedProblem } from "../utils/TextRender";
import "../GenerativeStyle.css";

const ProblemReeval = ({
    problems,
    existingProblems,
    navBarIndices,
    selection,
}) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { user } = useAuth0();
    const navigate = useNavigate();

    const [problemIx, setProblemIx] = useState(0);
    const [editableProblem, setEditableProblem] = useState("");
    const [savedProblemDict, setSavedProblemDict] = useState({});

    const [isNavConsoleVisible, setIsNavConsoleVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [submitAbility, setSubmitAbility] = useState(false);

    useEffect(() => {
        const complete =
            Object.keys(savedProblemDict).length ===
            Object.keys(problems).length;
        if (complete) {
            setSubmitAbility(true);
        } else {
            setSubmitAbility(false);
        }
    }, [savedProblemDict]);

    useEffect(() => {
        let newEditableProblem = null;

        // First check if the problemIx is in savedProblemDict, then check problems
        if (problemIx in savedProblemDict) {
            newEditableProblem = savedProblemDict[problemIx];
        } else if (problems && existingProblems) {
            newEditableProblem = problems[problemIx];
        }

        // Finally, set the state only if newEditableProblem is not null
        if (newEditableProblem !== null) {
            setEditableProblem(newEditableProblem);
        }
    }, [existingProblems, problemIx]); // List all dependencies

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            await GenericAsync({
                backendUrl: `${backendUrl}/api/confirm-reeval`,
                dataToSend: {
                    userEmail: user.email,
                    draftId: selection.draftId,
                },
            });
            navigate("/");
            setIsSubmitting(false);
        } catch (error) {
            console.log(error.message);
        }
    };

    const saveEdits = () => {
        setSavedProblemDict({
            ...savedProblemDict,
            [problemIx]: editableProblem,
        });
    };

    if (!problems || !editableProblem || !existingProblems) {
        return <div>Loading problems...</div>;
    }

    if (isSubmitting) {
        return (
            <div>
                Submitting... You will be taken to the home page after the
                submission is processed.
            </div>
        );
    }

    return (
        <div className="desktop-homepage">
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center", // justifyContent: 'space-between',
                        gap: "50px",
                        margin: "30px",
                        marginBottom: "300px",
                    }}
                >
                    <div style={{ width: "35%" }}>
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "24px",
                                marginBottom: "5px",
                            }}
                        >
                            Original Problem
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "16px",
                                marginBottom: "30px",
                                whiteSpace: "pre-line",
                            }}
                        >
                            {`Question ID: ${existingProblems[problemIx].question_id}; Category: ${existingProblems[problemIx].category};
                            Difficulty: ${existingProblems[problemIx].question_difficulty}`}
                        </div>

                        <MathJaxWrapper>
                            {returnFormattedProblem(
                                existingProblems[problemIx],
                                true,
                            )}
                        </MathJaxWrapper>
                    </div>
                    <div style={{ width: "35%" }}>
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "24px",
                                marginBottom: "70px",
                            }}
                        >
                            Question for Reeval
                        </div>
                        <MathJaxWrapper>
                            {returnFormattedProblem(
                                editableProblem,
                                true,
                                true,
                            )}
                        </MathJaxWrapper>
                    </div>
                </div>
                <div className="bottom-section">
                    <div style={{ flex: 1 }}>
                        <NavigationConsole
                            navBarIndices={navBarIndices}
                            currentQuestion={problemIx}
                            onSelectQuestion={(newIndex) =>
                                setProblemIx(newIndex)
                            }
                            isVisible={isNavConsoleVisible}
                            toggleVisibility={() =>
                                setIsNavConsoleVisible(!isNavConsoleVisible)
                            }
                            answeredQuestions={savedProblemDict}
                            handleSubmit={handleSubmit}
                            markForReview={{ accepted: {}, rejected: {} }}
                        />
                    </div>
                    <button
                        className="nav-button"
                        onClick={saveEdits}
                        style={{ width: "150px", margin: "5px" }}
                    >
                        Confirm Problem
                    </button>
                    <button
                        className={`nav-button ${submitAbility ? "" : "disabled"}`}
                        onClick={submitAbility ? handleSubmit : null}
                        disabled={!submitAbility}
                        style={{ margin: "5px" }}
                    >
                        Submit Reeval
                    </button>
                </div>
            </>
        </div>
    );
};
export default ProblemReeval;
