import PhoneInput from "react-phone-input-2";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";

const QuestionMarkIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
    >
        <g transform="translate(2 2) scale(0.8333)">
            <circle
                cx="12"
                cy="12"
                r="12"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M11.07 12.85C11.84 11.46 13.32 10.64 14.18 9.41C15.09 8.12 14.58 5.71 12 5.71C10.31 5.71 9.48004 6.99 9.13004 8.05L6.54004 6.96C7.25004 4.83 9.18004 3 11.99 3C14.34 3 15.95 4.07 16.77 5.41C17.47 6.56 17.88 8.71 16.8 10.31C15.6 12.08 14.45 12.62 13.83 13.76C13.58 14.22 13.48 14.52 13.48 16H10.59C10.58 15.22 10.46 13.95 11.07 12.85ZM14 20C14 21.1 13.1 22 12 22C10.9 22 10 21.1 10 20C10 18.9 10.9 18 12 18C13.1 18 14 18.9 14 20Z"
                fill="currentColor"
            />
        </g>
    </svg>
);

const EmailInputComponent = ({ onChange, email, setEmail, originalEmail }) => {
    const [isValid, setIsValid] = useState(true);

    const validateEmail = (email) => {
        const re = new RegExp(
            '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
        );
        return re.test(String(email).toLowerCase());
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        const valid = validateEmail(value);
        setIsValid(valid);

        onChange(valid ? value : originalEmail);
    };

    return (
        <div>
            <input
                type="email"
                className="settings-select-style"
                value={email}
                onChange={handleChange}
                style={{
                    borderBottom: `2px solid ${isValid ? "#a1a1a1" : "red"}`,
                }}
            />
            {!isValid && (
                <p style={{ color: "red", marginTop: "5px" }}>
                    Please enter a valid email address
                </p>
            )}
        </div>
    );
};

const PhoneInputComponent = ({
    onChange,
    phoneNumber,
    setPhoneNumber,
    originalNumber,
}) => {
    const [isValid, setIsValid] = useState(true);

    const handleChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value);

        const valid = isValidPhoneNumber("+" + value);
        setIsValid(valid);

        onChange(valid ? formattedValue : originalNumber);
    };

    return (
        <div>
            <PhoneInput
                country={"us"} // Set the default country
                value={phoneNumber}
                onChange={handleChange}
                inputStyle={{
                    width: "100%",
                    height: "36px",
                    fontSize: "16px",
                    paddingLeft: "48px", // Make room for the flag
                    backgroundColor: "#fcf4e6",
                    border: "none",
                    borderRadius: "0px",
                    borderBottom: `2px solid ${isValid ? "#a1a1a1" : "red"}`,
                }}
                containerStyle={{
                    marginBottom: "20px",
                }}
                dropdownStyle={{
                    width: "300px",
                }}
                buttonStyle={{
                    backgroundColor: "#fcf4e6",
                    border: "none",
                    borderRight: "none",
                }}
            />
            {!isValid && (
                <p style={{ color: "red", marginTop: "5px" }}>
                    Please enter a valid phone number
                </p>
            )}
        </div>
    );
};

const ToggleSwitch = ({ settings, setSettings }) => {
    const handleToggle = () => {
        setSettings({
            ...settings,
            textOptIn: !settings?.textOptIn,
        });
    };

    return (
        <div>
            <label className="toggle-switch">
                <input
                    type="checkbox"
                    checked={settings.textOptIn}
                    onChange={handleToggle}
                    className="toggle-switch-checkbox"
                />
                <span className="toggle-switch-slider"></span>
            </label>
        </div>
    );
};

export const PersonalInfoContainer = ({
    settings,
    setSettings,
    fixedEmail = true,
    addNewUser = false,
    optIntoText = false,
    applyPage = false,
}) => {
    const handleChange = (event) => {
        const { name, value } = event.target;
        setSettings({ ...settings, [name]: value });
    };
    const [email, setEmail] = useState(settings?.userEmail ?? "");
    const [originalEmail] = useState(settings?.userEmail ?? "");
    const [phoneNumber, setPhoneNumber] = useState(settings?.phoneNumber ?? "");
    const [originalNumber] = useState(settings?.phoneNumber ?? "");

    const APP_VERSION = process.env.REACT_APP_VERSION;

    function optInText() {
        if (applyPage) {
            return (
                <>
                    We strongly recommend opting into text notifications so that
                    you can receive updates on the status of your application.
                </>
            );
        } else {
            return (
                <>
                    We strongly recommend opting into text notifications so that
                    you can receive reminders about your ongoing work and
                    summaries of your completed steps, directly to your phone.
                </>
            );
        }
    }

    return (
        <div className="settings-unit">
            <div
                className="settings-container-title"
                style={{ paddingTop: "0px" }}
            >
                Personal Info
            </div>
            <div
                className="settings-text-container"
                style={{
                    width: applyPage && "100%",
                }}
            >
                <div className="settings-container-columns">
                    {!addNewUser && (
                        <>
                            <div className="settings-text-column">
                                <div className="settings-column-title">
                                    First Name:
                                    <span className="required-asterisk">*</span>
                                </div>
                                <textarea
                                    className="settings-select-style"
                                    style={{
                                        marginBottom: "10px",
                                        resize: "none",
                                        height: "36px",
                                    }}
                                    value={settings.userFirstName ?? ""}
                                    onChange={(e) =>
                                        setSettings({
                                            ...settings,
                                            userFirstName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="settings-text-column">
                                <div className="settings-column-title">
                                    Last Name:
                                    <span className="required-asterisk">*</span>
                                </div>
                                <textarea
                                    className="settings-select-style"
                                    style={{
                                        marginBottom: "10px",
                                        resize: "none",
                                        height: "36px",
                                    }}
                                    value={settings.userLastName ?? ""}
                                    onChange={(e) =>
                                        setSettings({
                                            ...settings,
                                            userLastName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </>
                    )}
                    <div className="settings-text-column">
                        <div className="settings-column-title">
                            Email:
                            <span className="required-asterisk">*</span>
                        </div>
                        {fixedEmail ? (
                            <div
                                className="settings-column-content"
                                style={{
                                    height: "45px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {settings?.userEmail}
                            </div>
                        ) : (
                            <EmailInputComponent
                                onChange={(value) =>
                                    setSettings({
                                        ...settings,
                                        userEmail: value,
                                    })
                                }
                                email={email}
                                setEmail={setEmail}
                                originalEmail={originalEmail}
                            />
                        )}
                    </div>
                    {!addNewUser && APP_VERSION === "sat" && (
                        <div className="settings-text-column">
                            <div className="settings-column-title">Grade:</div>
                            <select
                                name="grade"
                                value={settings.grade ?? ""}
                                onChange={handleChange}
                                className="settings-select-style"
                                style={{ marginBottom: "10px" }}
                            >
                                <option value="none">None</option>
                                <option value="12">12</option>
                                <option value="11">11</option>
                                <option value="10">10</option>
                                <option value="9">9</option>
                                <option value="<9">8 and under</option>
                            </select>
                        </div>
                    )}
                    {!addNewUser && (
                        <>
                            <div className="settings-text-column">
                                <div className="settings-column-title">
                                    Cell Phone:
                                    <span className="required-asterisk">*</span>
                                </div>
                                <PhoneInputComponent
                                    onChange={(value) =>
                                        setSettings({
                                            ...settings,
                                            phoneNumber: value,
                                        })
                                    }
                                    phoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                    originalNumber={originalNumber}
                                />
                            </div>
                            {optIntoText ? (
                                <div className="settings-text-column">
                                    <div className="settings-title-wrapper">
                                        <div className="settings-column-title">
                                            Opt Into Text Notifications:
                                        </div>
                                        <div className="info-icon-container">
                                            <QuestionMarkIcon />
                                            <span className="info-tooltip">
                                                {optInText()}
                                                <br />
                                                <br />
                                                You can opt out at any time.
                                            </span>
                                        </div>
                                    </div>
                                    <ToggleSwitch
                                        settings={settings}
                                        setSettings={setSettings}
                                    />
                                </div>
                            ) : (
                                <div className="settings-text-column" />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
