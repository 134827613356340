import "../Home.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "./Arrow";

const Everything = ({ pricing = false }) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
    const [featureIndex, setFeatureIndex] = useState(0);

    const features = [
        {
            title: "1:1 CHECK-INS WITH AN EXPERT TUTOR",
            description:
                "Benefit from regular sessions with a dedicated tutor who will also " +
                "serve as your motivator, curator, and cheerleader. " +
                "(Alia Premium and Alia Platinum level subscriptions only.)",
        },
        {
            title: "PERSONALIZED STUDY PLANS",
            description:
                "Stay focused and motivated with a strategic plan that keeps your " +
                "goals in sight and your progress on track.",
        },
        {
            title: "CURATED CONTENT",
            description:
                "Spend your time on accurate, vetted content that will closely reflect " +
                "the actual SAT.",
        },
        {
            title: "QUESTIONS WITH SELECTABLE DIFFICULTY",
            description:
                "Choose the difficulty of questions to challenge yourself and improve " +
                "progressively.",
        },
        {
            title: "SUMMARY REPORTS AFTER EACH SUBMISSION",
            description:
                "Receive summary reports containing an overview of your performance, " +
                "problems you missed, " +
                "and recommended strategies for next time.",
        },
        {
            title: "COLLEGE BOARD-STYLE ADAPTIVE EXAMS",
            description:
                "Practice with exams that adapt to your skill level, helping you " +
                "prepare effectively and efficiently.",
        },
        {
            title: 'TIMING AND "PROBLEM AREA" TARGETED FOCUS',
            description:
                "Sharpen your skills with a focus on improving timing and addressing " +
                "specific problem areas.",
        },
        {
            title: "MODULE 2 INTENSIVE TRAINING",
            description:
                "Tackle the toughest Reading/Writing and Math Module 2 problems with " +
                "intensive training that's designed to conquer the seemingly impossible.",
        },
    ];

    const incrementIndex = () => {
        setFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
    };

    const decrementIndex = () => {
        setFeatureIndex(
            (prevIndex) => (prevIndex - 1 + features.length) % features.length,
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div
            className="as-container"
            style={{ backgroundColor: pricing ? "#1e3a8a" : "#FFF" }}
        >
            <div className="as-content">
                <div
                    className="as-title"
                    style={
                        pricing
                            ? { paddingBottom: "50px", color: "#FFF" }
                            : { paddingBottom: "50px", color: "#016dea" }
                    }
                >
                    Everything You Need to Ace the SAT
                </div>
                <div>
                    {isMobile ? (
                        <div className="info-boxes-mobile-content">
                            <div className="info-content-grid">
                                <div className="info-box-single">
                                    <div className="info-header-grid">
                                        ✓ {features[featureIndex].title}
                                    </div>
                                    {features[featureIndex].description}
                                </div>
                            </div>
                            <div className="review-arrow-container">
                                <Arrow
                                    direction="left"
                                    onClick={() => decrementIndex()}
                                    className="hide-on-web-800"
                                    style={
                                        pricing
                                            ? { color: "#FFF" }
                                            : { color: "#016dea" }
                                    }
                                />
                                <Arrow
                                    direction="right"
                                    onClick={() => incrementIndex()}
                                    className="hide-on-web-800"
                                    style={
                                        pricing
                                            ? { color: "#FFF" }
                                            : { color: "#016dea" }
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="info-boxes-grid">
                            {features.map((feature, index) => (
                                <div key={index} className="info-box-grid">
                                    <div className="info-content-grid">
                                        <span className="info-header-grid">
                                            ✓ {feature.title}:
                                        </span>
                                        {feature.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {pricing && (
                    <div className="as-title" style={{ fontSize: "35px" }}>
                        All starting at $99/mo.
                    </div>
                )}
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Everything;
