import React, { useCallback, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import triggerEventing from "../advertising/Eventing";
import GenericAsync from "../backend/GenericAsync";

export const CheckoutForm = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    );

    const navigate = useNavigate();
    const location = useLocation();
    const { userEmail, subscriptionTier } = location.state || {};

    const fetchClientSecret = useCallback(async () => {
        try {
            const response = await fetch(
                `${backendUrl}/api/create-checkout-session`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...(userEmail && { userEmail }),
                        ...(subscriptionTier && { subscriptionTier }),
                    }),
                },
            );

            if (!response.ok) {
                throw new Error("Failed to create checkout session");
            }

            const data = await response.json();
            return data.clientSecret;
        } catch (error) {
            console.error("Error creating checkout session:", error);
            return null;
        }
    }, [backendUrl, userEmail, subscriptionTier, navigate]);

    const options = { fetchClientSecret };
    return (
        <div className="stripe-checkout-wrapper">
            <div className="stripe-checkout-form">
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        </div>
    );
};

export function CheckoutReturn() {
    const [processed, setProcessed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const checkoutComplete = queryParams.get("checkout_complete");
        const sessionId = queryParams.get("session_id");

        if (checkoutComplete === "true" && sessionId && !processed) {
            const processCheckoutReturn = async () => {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                try {
                    const response = await fetch(
                        `${backendUrl}/api/session-status?session_id=${sessionId}`,
                    );
                    const data = await response.json();
                    if (data.status === "complete") {
                        try {
                            const eventId = crypto.randomUUID();
                            const auth0_email = data?.auth0_email;

                            await GenericAsync({
                                backendUrl: `${backendUrl}/api/track-stripe-conversion`,
                                dataToSend: {
                                    userEmail: auth0_email,
                                    eventID: eventId,
                                    sessionId: sessionId,
                                },
                            });

                            triggerEventing({
                                userEmail: auth0_email,
                                eventName: "CompleteRegistration",
                                eventId: eventId,
                            });
                        } catch (error) {
                            console.error("Error tracking conversion:", error);
                        }
                    }
                } catch (error) {
                    console.error("Error processing checkout return:", error);
                }
                setProcessed(true);
            };

            processCheckoutReturn();
        }
    }, [location, processed, navigate]);

    return null;
}
