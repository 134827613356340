import alia_logo_blue from "../../../assets/alia_logo_blue.svg";
import alia_logo_white from "../../../assets/alia_logo_white.svg";
import React from "react";

import { useNavigate } from "react-router-dom";

const BottomBar = ({ applyPage = false }) => {
    const navigate = useNavigate();

    const login = () => {
        const loginUrl = new URL("https://sat.aliaeducation.com");
        window.location.href = loginUrl.toString();
    };

    return (
        <div
            className="ws-nav-container"
            style={{
                paddingTop: "25px",
                paddingBottom: "25px",
                backgroundColor: applyPage && "#016dea",
            }}
        >
            <div className="bottom-lhs expand-on-1100">
                <div className="ws-logo-container">
                    <img
                        src={applyPage ? alia_logo_white : alia_logo_blue}
                        style={{
                            maxWidth: "88px",
                            height: "50px",
                            cursor: "pointer",
                        }}
                        alt="Alia Logo"
                        className="ws-logo"
                        onClick={() => (window.location.href = "/")}
                    />
                </div>
                <div className="hide-on-mobile-1100">
                    <div
                        className="ws-nav-buttons"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        {!applyPage && (
                            <>
                                <button
                                    className="ws-nav-button"
                                    style={{ minWidth: "150px" }}
                                    onClick={() => navigate("/how-it-works")}
                                >
                                    HOW IT WORKS
                                </button>
                                <button
                                    className="ws-nav-button"
                                    style={{ minWidth: "100px" }}
                                    onClick={() => navigate("/pricing")}
                                >
                                    PRICING
                                </button>
                                <button
                                    className="ws-nav-button"
                                    style={{ minWidth: "110px" }}
                                    onClick={() => login()}
                                >
                                    LOG IN
                                </button>
                                <button
                                    className="ws-nav-button"
                                    style={{ minWidth: "110px" }}
                                    onClick={() => navigate("/apply")}
                                >
                                    APPLY NOW
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="bottom-rhs hide-on-mobile-550">
                <div
                    className="ws-nav-button-bottom"
                    style={{ cursor: "default", color: applyPage && "#FFF" }}
                >
                    TEAM@ALIAEDUCATION.COM&nbsp;&nbsp;|&nbsp;&nbsp;(201)
                    431-5867
                </div>
            </div>
        </div>
    );
};

export default BottomBar;
