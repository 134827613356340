import { useEffect } from "react";

const useScriptLoader = (src) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [src]);
};

export default useScriptLoader;
