import "../Home.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import alia_logo_white from "../../../assets/alia_logo_white.svg";
import BottomBar from "./BottomBar";

const ApplyConfirmation = () => {
    const navigate = useNavigate();

    return (
        <div
            className="ws-home-page"
            style={{
                backgroundColor: "#016dea",
                minHeight: "101vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                className="apply-content"
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div className="apply-logo-container">
                    <img
                        src={alia_logo_white}
                        style={{
                            maxWidth: "100px",
                            maxHeight: "50px",
                        }}
                        alt="Alia Logo"
                        className="apply-logo"
                        onClick={() => navigate("/")}
                    />
                </div>
                <div className="ac-content-confirmation">
                    <div className="as-title" style={{ padding: "30px" }}>
                        Your Application Is In!
                    </div>
                    <div className="apply-banner-text">
                        Thank you for taking the time to complete your
                        application. Our team will carefully review your
                        submission ahead of your interview. We look forward to
                        meeting you and discussing how you can make the most of
                        Alia&apos;s SAT prep program.
                    </div>
                    <div className="apply-banner-text">
                        Keep an eye on your inbox for more details!
                    </div>
                    <button
                        className={"apply-button"}
                        style={{
                            color: "#003169",
                            backgroundColor: "#FFAB24",
                        }}
                        onClick={() => navigate("/")}
                    >
                        BACK TO HOMEPAGE
                    </button>
                </div>
            </div>
            <BottomBar applyPage={true} />
        </div>
    );
};

export default ApplyConfirmation;
