import React from "react";
import { useNavigate } from "react-router-dom";

const Invest = () => {
    const navigate = useNavigate();
    return (
        <div
            className="hero-container"
            style={{
                textAlign: "center",
                justifyContent: "center",
                paddingBottom: "80px",
            }}
        >
            <div className="hero-text" style={{ alignItems: "center" }}>
                <h1>Invest in Your Success</h1>
                <p style={{ textAlign: "center" }}>
                    Experience personalized study plans, expert tutoring, and
                    adaptive practice exams designed to take your score to the
                    next level.
                </p>
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Invest;
