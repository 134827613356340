import React, { useEffect, useState, useRef } from "react";
import formatUTCTime from "../../utils/FormatUTCTime";
import formatString from "../../utils/TextProcessing";
import { useUserProfile } from "../../auth/UserProfileContext";
import GenericAsync from "../../backend/GenericAsync";
import ThreeDotMenu from "../../../../assets/three-dot-menu.svg";
import "../../common/Style.css";
import ConfirmModal from "../../popups/ConfirmModal";
import { useError } from "../../common/ErrorContext";
import { useJourney } from "../../common/JourneyContext";
import ClickThroughButton from "../../common/ClickThroughButton";
import { useNavigate } from "react-router-dom";

const SelectStepContainer = ({ activeStep, stepType, stepModal = false }) => {
    const { profile } = useUserProfile();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const dropDownRef = useRef(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalStage, setModalStage] = useState(1); // 1 for selecting topics, 2 for generating test
    const { triggerError } = useError();
    const { setJourney } = useJourney();
    const navigate = useNavigate();

    const handleDownloadResults = async () => {
        const s3Uri = activeStep.status_file;

        try {
            const result = await GenericAsync({
                backendUrl: `${backendUrl}/api/download-user-results`,
                dataToSend: {
                    userEmail: profile.email,
                    s3Uri: s3Uri,
                },
                jsonify: false,
            });

            const blob = await result.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            const filename = `${activeStep.step}_status.csv`;

            link.href = url;
            link.setAttribute("download", filename);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log(error.message);
        }
    };

    const viewSummary = () => {
        try {
            // Check if summary_table exists and is not empty
            if (!activeStep.summary_table) {
                throw new Error("Summary table is not available");
            }

            // Create a new tab
            const newTab = window.open("", "_blank");

            // Write the HTML content to the new tab
            newTab.document.write(activeStep.summary_table);
            newTab.document.close();

            // Optional: You can set the title of the new tab
            newTab.document.title = "Step Summary";
        } catch (error) {
            console.error("Error displaying the summary:", error);
            alert(
                "There was an error displaying the summary. Please try again.",
            );
        }
    };

    const review = () => {
        navigate(`/${activeStep.url_ext}`, {
            state: { activeStep: { ...activeStep, review: true } },
        });
    };

    function updateProgressBar(fraction) {
        const progressBar = document.getElementById("progressBar");
        const progressBarText = document.getElementById("progressBarText");
        const percentage = 100 * fraction;

        if (progressBar) {
            progressBar.style.width = percentage + "%";
            progressBarText.textContent = percentage.toFixed(0) + "%"; // Adjust text content
        }
    }

    const handleDropDownClick = () => {
        setShowConfirmModal(true);
    };

    const handleClickOutside = (event) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target)
        ) {
            setDropDownMenuOpen(false);
        }
    };

    const handleDeleteStep = async () => {
        setModalStage(2); // Switch to generating state

        try {
            const result = await GenericAsync({
                backendUrl: `${backendUrl}/api/delete-step`,
                dataToSend: {
                    userEmail: profile.email,
                    step: activeStep.step,
                },
            });
            if (result.success) {
                const newJourney = result.journey;
                setJourney(newJourney);

                setShowConfirmModal(false);
                setModalStage(1); // Reset to initial stage
            }
        } catch (error) {
            triggerError(error.message);
        }
    };

    const onClosePopup = () => {
        setShowConfirmModal(false); // Simply close the modal
        setModalStage(1); // Reset to initial stage
    };

    useEffect(() => {
        if (dropDownMenuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownMenuOpen]);

    function returnMenuButton() {
        if (activeStep && activeStep.status !== "complete" && !stepModal) {
            return (
                <div className="top-level-menu-container">
                    <button
                        onClick={() => setDropDownMenuOpen(true)}
                        className="menu-container"
                    >
                        <img
                            alt="menu"
                            className="menu-icon"
                            style={{ border: 0 }}
                            src={ThreeDotMenu}
                        />
                    </button>
                    {dropDownMenuOpen && (
                        <div
                            ref={dropDownRef}
                            className="three-dot-dropdown-menu"
                        >
                            <button
                                onClick={handleDownloadResults}
                                className="three-dot-dropdown-item"
                            >
                                Download Results
                            </button>
                            <button
                                onClick={handleDropDownClick}
                                className="three-dot-dropdown-item"
                            >
                                Delete Step
                            </button>
                        </div>
                    )}
                </div>
            );
        }
    }

    useEffect(() => {
        if (activeStep && activeStep.status !== "complete") {
            const frac = activeStep.n_correct / activeStep.n_total;
            updateProgressBar(frac);
        }
    }, [activeStep]);

    if (!activeStep) {
        return (
            <div className="single-task">
                <div className="task-title" style={{ marginTop: "40px" }}>
                    No current {stepType.replace("-", " ")}!
                </div>
            </div>
        );
    }

    function returnModalText() {
        return (
            <div style={{ maxWidth: "600px" }}>
                {`Are you sure you want to delete ${activeStep.step_name}?`}
                <br /> <br />
                {`Another step will be automatically generated if this leaves you with no next steps on your Journey.`}
            </div>
        );
    }

    function returnContainerStatus() {
        let status_string;
        let date;
        if (activeStep.status === "complete") {
            status_string = "Completed";
            date = activeStep.complete_date;
        } else if (activeStep.status === "not_started") {
            status_string = "Assigned";
            date = activeStep.assign_date;
        } else {
            status_string = "Started";
            date = activeStep.start_date;
        }

        return (
            <>
                <div className="container-section-title">
                    Status: {formatString(activeStep.status)}
                </div>
                <div
                    className="container-section-title"
                    style={{ marginBottom: "0px" }}
                >
                    {status_string}: {formatUTCTime(date)}
                </div>
            </>
        );
    }

    function returnSummaryLogic() {
        if (activeStep?.summary_file === "loading") {
            return (
                <button
                    className="orange-button disabled"
                    style={{ marginTop: "30px", width: "280px" }}
                    disabled={true}
                >
                    Generating Summary...
                </button>
            );
        } else if (activeStep?.summary_table !== "none") {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <button
                        className="orange-button"
                        style={{ margin: "30px" }}
                        onClick={() => viewSummary()}
                    >
                        View Summary
                    </button>
                    <button
                        className="orange-button"
                        style={{
                            margin: "30px",
                            backgroundColor: "#003169",
                            borderColor: "#003169",
                        }}
                        onClick={() => review()}
                    >
                        Review
                    </button>
                </div>
            );
        }
    }

    function returnContainer(activeStep) {
        return (
            <>
                {returnContainerStatus()}
                {activeStep?.progress_table !== "none" ? (
                    <>
                        {stepModal && (
                            <div className="container-section-title">
                                Initial Submission Results:
                            </div>
                        )}
                        <div
                            className="table-container hide-on-mobile"
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                marginTop: "0px",
                                fontFamily: "DM Sans",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: activeStep.progress_table,
                            }}
                        ></div>
                    </>
                ) : (
                    <div className="container-section-title">
                        Total Questions: {activeStep.n_total}
                    </div>
                )}

                {activeStep.status === "complete" ? (
                    returnSummaryLogic()
                ) : (
                    <>
                        <div
                            className="container-section-title"
                            style={{ marginTop: "20px" }}
                        >
                            Progress:
                        </div>
                        <div
                            id="progressBarContainer"
                            className="progress-bar-container"
                        >
                            <div
                                id="progressBar"
                                className="progress-bar"
                            ></div>
                            <div
                                id="progressBarText"
                                className="progress-bar-text"
                            ></div>
                        </div>

                        <ClickThroughButton
                            activeStep={activeStep}
                            className={"orange-button"}
                            style={{
                                marginTop: "0px",
                                width: "250px",
                                marginBottom: "20px",
                            }}
                        ></ClickThroughButton>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            {returnMenuButton()}
            <div
                className="single-task"
                style={{ width: stepModal ? "100%" : "90%" }}
            >
                <>
                    <div
                        className="task-title"
                        style={{ marginBottom: "10px" }}
                    >
                        {activeStep.step_name}
                    </div>
                    {returnContainer(activeStep)}
                    <ConfirmModal
                        isOpen={showConfirmModal}
                        onConfirm={modalStage === 1 ? handleDeleteStep : null}
                        onClose={onClosePopup}
                        message={
                            modalStage === 1 ? returnModalText() : "Deleting..."
                        }
                        confirmMessage={modalStage === 1 ? "Delete" : ""}
                        declineMessage="Cancel"
                        showActions={modalStage === 1}
                    ></ConfirmModal>
                </>
            </div>
        </>
    );
};

export default SelectStepContainer;
