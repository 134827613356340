import React from "react";

const TopBanner = () => {
    return (
        <div className="ws-top-banner">
            <div className="ws-top-banner-content">
                <div className="ws-top-banner-center">
                    <div>
                        🌟 December cohort is almost full - Apply now to reserve
                        your spot!
                    </div>
                    <a
                        href="/apply"
                        style={{
                            color: "#FFAB24",
                            textDecoration: "none",
                            cursor: "pointer",
                        }}
                    >
                        Apply Now →
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TopBanner;
