import "./Modal.css";

const GenericModal = ({
    isOpen,
    onConfirm,
    children,
    message,
    confirmMessage,
    declineMessage = null,
    onClose = null,
    showActions = true,
}) => {
    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                    {message}
                </div>
                {children}
                {showActions && (
                    <div className="modal-actions center-actions">
                        <button onClick={onConfirm}>{confirmMessage}</button>
                        {onClose ? (
                            <button onClick={onClose}>{declineMessage}</button>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default GenericModal;
