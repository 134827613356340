import React, { useState } from "react";
import "../Home.css";
import { ChevronDown, ChevronUp } from "lucide-react";
import pset_image from "../../../assets/website/pset_categories.png";
import sample_question from "../../../assets/website/sample_question.png";
import settings_png from "../../../assets/website/settings.png";
import { useNavigate } from "react-router-dom";

const Advantage = () => {
    const navigate = useNavigate();
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const advantageItems = [
        {
            title: "1:1 TUTORING AND SUPPORT",
            content:
                "Each student benefits from the dedicated attention of a real expert " +
                "who checks in regularly, offering both emotional and objective " +
                "support. This personal connection is the cornerstone of our " +
                "service, providing the scaffolding you need to excel in your exams.",
        },
        {
            title: "CURATED CONTENT",
            content:
                "Our content is curated by a team of expert tutors to ensure that it's " +
                "the highest quality and will reflect exactly what you see on test day. " +
                "With Alia, " +
                "you can be confident that all our study material is accurate, " +
                "and that you will not waste your time with unvetted outputs from an " +
                "AI model or a generic testing company.",
        },
        {
            title: "SESSION MAXIMIZATION",
            content:
                "Between regular check-ins with an expert tutor and " +
                "best-in-class practice material, we ensure that you will get the most " +
                "out of your preparation, and that you only spend time on material " +
                "well-aligned with your test goals and knowledge gaps.",
        },
        {
            title: "INSIGHTFUL ANALYTICS",
            content:
                "The Alia Education Platform tracks your progress along your study " +
                "journey, providing insights and recommendations for where you need to " +
                "focus next in order to achieve your goal score.",
        },
    ];

    return (
        <div className="as-container">
            <div className="as-content">
                <div className="as-title">Experience The Alia Advantage</div>

                <div className="as-middle">
                    <div className="as-left-container hide-on-mobile-1000">
                        <div className="as-image-layout">
                            <div className="as-image-container">
                                <img
                                    src={pset_image}
                                    alt="PSET image"
                                    className="as-tall-image"
                                />
                            </div>
                            <div className="as-image-stack">
                                <div className="as-image-container">
                                    <img
                                        src={sample_question}
                                        alt="Sample question"
                                        className="as-short-image"
                                    />
                                </div>
                                <div className="as-image-container">
                                    <img
                                        src={settings_png}
                                        alt="Settings"
                                        className="as-short-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="as-right-container">
                        {advantageItems.map((item, index) => (
                            <div key={index} className="as-dropdown">
                                <div
                                    className="as-dropdown-header"
                                    onClick={() => toggleDropdown(index)}
                                >
                                    <div className="as-dropdown-text">
                                        {item.title}
                                    </div>
                                    {openDropdown === index ? (
                                        <ChevronUp size={32} color="#EC5725" />
                                    ) : (
                                        <ChevronDown
                                            size={32}
                                            color="#FFAB24"
                                        />
                                    )}
                                </div>
                                {openDropdown === index && (
                                    <div
                                        className="as-text"
                                        style={{ paddingLeft: "20px" }}
                                    >
                                        {item.content}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Advantage;
