import { Route, Routes, useLocation } from "react-router-dom";
import DesktopHomepage from "./components/website/Home";
import HowItWorks from "./components/website/HowItWorks";
import Pricing from "./components/website/Pricing";
import Apply from "./components/website/Apply";
import ApplyConfirmation from "./components/website/sections/ApplyConfirmation";

import React, { useEffect } from "react";
import SVGSprite from "./assets/website/SVGSprite";
import { useMetaPixel } from "./components/shared/advertising/MetaPixel";
import useScriptLoader from "./components/shared/utils/ScriptLoading";
import { useTikTokPixel } from "./components/shared/advertising/TikTokPixel";
import {
    CheckoutReturn,
    CheckoutForm,
} from "./components/shared/stripe/Checkout";

function Website() {
    useScriptLoader(
        "https://cdn.jsdelivr.net/npm/@statsig/js-client@3/build/statsig-js-client+session-replay+web-analytics.min.js?apikey=client-lSNE8hcT0NH0dtwmoO1NXB3lkiqa1vEHSQR4iiaRfRh",
    );
    useMetaPixel();
    useTikTokPixel();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("/signup")) {
            const params = new URLSearchParams(window.location.search);
            const email = params.get("email");

            const signupUrl = new URL(
                "https://auth0.platform.aliaeducation.com/authorize",
            );
            signupUrl.searchParams.append("response_type", "code");
            signupUrl.searchParams.append(
                "client_id",
                "TQn5yos8s6SeNwFtOydPoz5PS4V21oHW",
            );
            signupUrl.searchParams.append(
                "redirect_uri",
                "https://sat.aliaeducation.com",
            );
            signupUrl.searchParams.append("screen_hint", "signup");
            signupUrl.searchParams.append("state", Date.now().toString());

            if (email) {
                signupUrl.searchParams.append("prompt", "login");
                signupUrl.searchParams.append("login_hint", email);
            }

            window.location.href = signupUrl.toString();
        }
    }, [location]);

    function SiteRoutes() {
        return (
            <>
                <SVGSprite />
                <CheckoutReturn />
                <Routes>
                    <Route path="/" element={<DesktopHomepage />} />
                    <Route path="/blog" element={<DesktopHomepage />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/apply" element={<Apply />} />
                    <Route
                        path="/apply-confirmation"
                        element={<ApplyConfirmation />}
                    />
                    <Route path="/checkout" element={<CheckoutForm />} />
                </Routes>
            </>
        );
    }

    return <SiteRoutes />;
}

export default Website;
