import React from "react";

const ImageGenerator = ({
    editableProblem,
    setEditableProblem,
    generateImage,
}) => {
    const showImageGenerator =
        editableProblem.image_url === "TO FILL" ||
        editableProblem.image_url.startsWith("s3://");

    if (!showImageGenerator) {
        return null;
    }

    const handlePythonCodeChange = (e) => {
        setEditableProblem({ ...editableProblem, python_code: e.target.value });
    };

    return (
        <div style={{ width: "25%" }}>
            <div
                style={{
                    textAlign: "center",
                    fontSize: "24px",
                    marginBottom: "70px",
                }}
            >
                Generated Problem — Code for Image
            </div>
            <div>
                Python Code:{" "}
                <textarea
                    style={{
                        width: "100%",
                        height: "200px",
                        marginBottom: "10px",
                    }}
                    value={editableProblem.python_code}
                    onChange={handlePythonCodeChange}
                />
            </div>
            <button
                className="nav-button"
                onClick={generateImage}
                style={{ width: "150px" }}
            >
                Generate Image
            </button>
        </div>
    );
};

export default ImageGenerator;
