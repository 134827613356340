import { trackFBEvent } from "./MetaPixel";
import { trackTikTokEvent } from "./TikTokPixel";

function triggerEventing({ userEmail, eventName, eventId }) {
    trackFBEvent({
        userEmail,
        eventName,
        eventId,
    });

    trackTikTokEvent({
        userEmail,
        eventName,
        eventId,
    });
}

export default triggerEventing;
