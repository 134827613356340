import React from "react";

const SVGSprite = () => (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="stars" viewBox="0 0 155 31">
            <path
                id="Star 1"
                d="M15.5 0L18.98 10.7102H30.2414L21.1307 17.3295L24.6107 28.0398L15.5 21.4205L6.38933 28.0398L9.8693 17.3295L0.758624 10.7102H12.02L15.5 0Z"
                fill="#FFAB24"
            />
            <path
                id="Star 2"
                d="M46.5 0L49.98 10.7102H61.2414L52.1307 17.3295L55.6107 28.0398L46.5 21.4205L37.3893 28.0398L40.8693 17.3295L31.7586 10.7102H43.02L46.5 0Z"
                fill="#FFAB24"
            />
            <path
                id="Star 3"
                d="M77.5 0L80.98 10.7102H92.2414L83.1307 17.3295L86.6107 28.0398L77.5 21.4205L68.3893 28.0398L71.8693 17.3295L62.7586 10.7102H74.02L77.5 0Z"
                fill="#FFAB24"
            />
            <path
                id="Star 4"
                d="M108.5 0L111.98 10.7102H123.241L114.131 17.3295L117.611 28.0398L108.5 21.4205L99.3893 28.0398L102.869 17.3295L93.7586 10.7102H105.02L108.5 0Z"
                fill="#FFAB24"
            />
            <path
                id="Star 5"
                d="M139.5 0L142.98 10.7102H154.241L145.131 17.3295L148.611 28.0398L139.5 21.4205L130.389 28.0398L133.869 17.3295L124.759 10.7102H136.02L139.5 0Z"
                fill="#FFAB24"
            />
        </symbol>

        <symbol id="left-curved-arrow" viewBox="0 0 100 217">
            <path
                d="M99.3816 204L71.613 216.518L74.656 186.211L99.3816 204ZM99.3816 5.6379C42.444 5.6379 10.6863 48.2611 6.18945 94.1059C1.68489 140.029 24.618 188.118 76.2969 199.047L75.2054 204.208C20.1359 192.563 -3.74456 141.338 0.93885 93.5909C5.62996 45.7656 39.0617 0.362096 99.3816 0.362096V5.6379Z"
                fill="#EC5725"
            />
        </symbol>

        <symbol
            id="right-curved-arrow"
            width="100"
            height="217"
            viewBox="0 0 100 217"
            fill="none"
        >
            <path
                d="M0 204L27.7686 216.518L24.7256 186.211L0 204ZM0 5.6379C56.9376 5.6379 88.6953 48.2611 93.1921 94.1059C97.6967 140.029 74.7636 188.118 23.0847 199.047L24.1762 204.208C79.2457 192.563 103.126 141.338 98.4427 93.5909C93.7516 45.7656 60.3199 0.362096 0 0.362096V5.6379Z"
                fill="#EC5725"
            />
        </symbol>

        <symbol id="progress-line" width="31" height="26" viewBox="0 0 31 29">
            <path
                id="Vector"
                d="M20.1446 28.4068C16.0888 28.4068 12.033 28.4108 7.97726 28.4058C4.21408 28.4009 1.05652 26.3281 0.197872 23.3073C0.0695552 22.8566 0 22.398 0 21.9334C0 15.1011 0 8.26882 0 1.43655C0 0.691244 0.726732 0.218762 1.50863 0.45204C1.89718 0.56769 2.15141 0.792068 2.23056 1.12815C2.26894 1.29124 2.27254 1.46225 2.27254 1.62931C2.27493 8.34593 2.27254 15.0626 2.27613 21.7792C2.27613 23.9617 3.89149 25.7548 6.3679 26.3528C6.94952 26.4932 7.54194 26.5327 8.14395 26.5327C16.2687 26.5307 24.3923 26.5317 32.517 26.5327C32.7077 26.5327 32.902 26.5337 33.0867 26.5643C33.6419 26.6553 34.0149 27.0645 33.9837 27.5241C33.9525 27.9808 33.5184 28.3545 32.9608 28.3999C32.8348 28.4108 32.7053 28.4058 32.5782 28.4058C28.4337 28.4058 24.2879 28.4058 20.1434 28.4058L20.1446 28.4068Z"
                fill="#EC5725"
            />
            <path
                id="Vector_2"
                d="M21.5106 18.1307C21.1208 18.1366 20.8426 17.9656 20.5824 17.7501C18.7368 16.2239 16.8804 14.7056 15.0456 13.1706C14.7757 12.9452 14.6522 12.9739 14.4028 13.1814C12.1854 15.0259 9.95124 16.8565 7.72787 18.6961C7.38969 18.9758 7.01553 19.1429 6.52984 19.042C5.664 18.8621 5.37618 18.0111 6.00818 17.4714C6.64617 16.9267 7.30454 16.3989 7.95452 15.8641C9.90327 14.2589 11.8508 12.6526 13.7996 11.0473C14.4303 10.5274 15.0264 10.5254 15.6572 11.0444C17.48 12.5449 19.3064 14.0434 21.1172 15.5538C21.3631 15.7584 21.477 15.7702 21.7037 15.535C24.282 12.8602 26.8747 10.1953 29.4627 7.52741C29.5418 7.44536 29.6198 7.36135 29.7037 7.28227C30.1294 6.88293 30.7926 6.81966 31.2807 7.13103C31.7772 7.44833 31.8755 8.02065 31.4666 8.45063C30.5144 9.45195 29.5454 10.4404 28.5812 11.4338C26.5665 13.5106 24.5482 15.5844 22.5359 17.6641C22.2673 17.9419 21.9639 18.1406 21.5106 18.1317V18.1307Z"
                fill="#EC5725"
            />
        </symbol>
    </svg>
);

export default SVGSprite;
