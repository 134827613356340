import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import GenericAsync from "../../shared/backend/GenericAsync";
import MathJaxWrapper from "../../shared/utils/MathJaxWrapper";
import NavigationConsole from "./NavigationConsole";
import { returnFormattedProblem } from "../utils/TextRender";
import "../GenerativeStyle.css";
import EditableProblem from "../utils/EditableProblem";

const ProblemEdit = ({ problems, navBarIndices, selection }) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { user } = useAuth0();
    const navigate = useNavigate();

    const [problemIx, setProblemIx] = useState(0);
    const [editableProblem, setEditableProblem] = useState(null);
    const [savedProblemDict, setSavedProblemDict] = useState({});

    const [isNavConsoleVisible, setIsNavConsoleVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [submitAbility, setSubmitAbility] = useState(false);

    useEffect(() => {
        const complete =
            Object.keys(savedProblemDict).length ===
            Object.keys(problems).length;
        if (complete) {
            setSubmitAbility(true);
        } else {
            setSubmitAbility(false);
        }
    }, [savedProblemDict]);

    useEffect(() => {
        let newEditableProblem = null;

        // First check if the problemIx is in savedProblemDict, then check problems
        if (problemIx in savedProblemDict) {
            newEditableProblem = savedProblemDict[problemIx];
        } else if (problems) {
            newEditableProblem = problems[problemIx];
        }

        // Finally, set the state only if newEditableProblem is not null
        if (newEditableProblem !== null) {
            setEditableProblem(newEditableProblem);
        }
    }, [problemIx]); // List all dependencies

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            await GenericAsync({
                backendUrl: `${backendUrl}/api/save-single-question`,
                dataToSend: {
                    userEmail: user.email,
                    questions: savedProblemDict,
                },
            });
            navigate("/");
            setIsSubmitting(false);
        } catch (error) {
            console.log(error.message);
        }
    };

    const saveEdits = () => {
        setSavedProblemDict({
            ...savedProblemDict,
            [problemIx]: editableProblem,
        });
    };

    if (!problems || !editableProblem) {
        return <div>Loading problems...</div>;
    }

    if (isSubmitting) {
        return (
            <div>
                Submitting... You will be taken to the home page after the
                submission is processed.
            </div>
        );
    }

    return (
        <div className="desktop-homepage">
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center", // justifyContent: 'space-between',
                        gap: "50px",
                        margin: "30px",
                        marginBottom: "300px",
                    }}
                >
                    <div style={{ width: "33%" }}>
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "24px",
                                marginBottom: "70px",
                            }}
                        >
                            Question to Edit
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "16px",
                                marginBottom: "30px",
                                whiteSpace: "pre-line",
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                            }}
                        >
                            <span>
                                {`Question ID: ${problems[problemIx].question_id}; Category: ${problems[problemIx].category};
                                Difficulty: ${problems[problemIx].question_difficulty}`}
                            </span>
                            <span>
                                Note: Do not change the correct answer unless
                                absolutely necessary! Reach out to Terry/Rishi
                                if you do need to do this.
                            </span>
                        </div>
                        <MathJaxWrapper>
                            {returnFormattedProblem(editableProblem, true)}
                        </MathJaxWrapper>
                    </div>
                    <EditableProblem
                        problem={editableProblem}
                        setProblem={setEditableProblem}
                        reviewType={selection.reviewType}
                    />
                </div>
                <div className="bottom-section">
                    <div style={{ flex: 1 }}>
                        <NavigationConsole
                            navBarIndices={navBarIndices}
                            currentQuestion={problemIx}
                            onSelectQuestion={(newIndex) =>
                                setProblemIx(newIndex)
                            }
                            isVisible={isNavConsoleVisible}
                            toggleVisibility={() =>
                                setIsNavConsoleVisible(!isNavConsoleVisible)
                            }
                            answeredQuestions={savedProblemDict}
                            handleSubmit={handleSubmit}
                            markForReview={{ accepted: {}, rejected: {} }}
                        />
                    </div>
                    <button
                        className="nav-button"
                        onClick={saveEdits}
                        style={{ width: "150px", margin: "5px" }}
                    >
                        Save Edits
                    </button>
                    <button
                        className={`nav-button ${submitAbility ? "" : "disabled"}`}
                        style={{ margin: "5px" }}
                        onClick={submitAbility ? handleSubmit : null}
                        disabled={!submitAbility}
                    >
                        Submit Edits
                    </button>
                </div>
            </>
        </div>
    );
};
export default ProblemEdit;
