import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import alia_logo_blue from "../../../assets/alia_logo_blue.svg";
import alia_logo_white from "../../../assets/alia_logo_white.svg";
import mobile_dropdown from "../../../assets/website/mobile_dropdown.svg";

const DropDownScreen = ({ toggleMenu }) => {
    const navigate = useNavigate();
    const login = () => {
        const loginUrl = new URL("https://sat.aliaeducation.com");
        window.location.href = loginUrl.toString();
    };

    return (
        <div className="ws-full-screen">
            <div className="mobile-dropdown-column">
                <div
                    className="ws-dropdown-menu"
                    style={{ width: "60%", paddingBottom: "100px" }}
                >
                    <div style={{ width: "40px" }} />
                    <img
                        src={alia_logo_white}
                        style={{
                            maxWidth: "100px",
                            maxHeight: "50px",
                            cursor: "pointer",
                        }}
                        alt="Alia Logo"
                        className="ws-logo"
                        onClick={() => navigate("/")}
                    />
                    <div className="ws-full-screen-exit" onClick={toggleMenu}>
                        X
                    </div>
                </div>
                <div className="mobile-dropdown-items">
                    <div
                        className="mobile-dropdown-item"
                        onClick={() => {
                            navigate("/how-it-works");
                            toggleMenu();
                        }}
                    >
                        HOW IT WORKS
                    </div>
                    <div
                        className="mobile-dropdown-item"
                        onClick={() => {
                            navigate("/pricing");
                            toggleMenu();
                        }}
                    >
                        PRICING
                    </div>
                    <div
                        className="mobile-dropdown-item"
                        onClick={() => {
                            navigate("/apply");
                            toggleMenu();
                        }}
                    >
                        APPLY
                    </div>
                    <div
                        className="mobile-dropdown-item"
                        onClick={() => {
                            login();
                            toggleMenu();
                        }}
                    >
                        LOG IN
                    </div>
                </div>
            </div>
        </div>
    );
};

const DropdownMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [isOpen]);

    return (
        <div className="ws-dropdown-menu">
            {isOpen ? (
                <DropDownScreen toggleMenu={toggleMenu} />
            ) : (
                <>
                    <div style={{ width: "40px" }} />
                    <img
                        src={alia_logo_blue}
                        style={{
                            maxWidth: "100px",
                            maxHeight: "50px",
                            cursor: "pointer",
                        }}
                        alt="Alia Logo"
                        className="ws-logo"
                        onClick={() => navigate("/")}
                    />

                    <img
                        src={mobile_dropdown}
                        alt="Dropdown"
                        className="mobile-dropdown"
                        onClick={toggleMenu}
                    />
                </>
            )}
        </div>
    );
};

export default DropdownMenu;
