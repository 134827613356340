// src/components/NavigationButtons.js
import React from "react";

const NavigationButtons = ({
    currentQuestionIndex,
    totalQuestions,
    onNavigate,
    onSubmit,
    review,
}) => {
    return (
        <div className="navigation">
            {currentQuestionIndex > 0 && (
                <button
                    className="nav-button"
                    onClick={() => onNavigate(currentQuestionIndex - 1)}
                >
                    Back
                </button>
            )}
            {currentQuestionIndex < totalQuestions - 1 && (
                <button
                    className="nav-button"
                    onClick={() => onNavigate(currentQuestionIndex + 1)}
                >
                    Next
                </button>
            )}
            {currentQuestionIndex === totalQuestions - 1 && !review && (
                <button className="nav-button" onClick={onSubmit}>
                    Submit
                </button>
            )}
        </div>
    );
};

export default NavigationButtons;
