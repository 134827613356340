import "./Modal.css";
import React, { useEffect, useState } from "react";
import GetImageSrc from "../../shared/utils/GetImageSrc";

const ImageModal = ({ isOpen, imgData, onConfirm, onClose }) => {
    const [imageValid, setImageValid] = useState(false);

    useEffect(() => {
        if (imgData && imgData.success) {
            setImageValid(true);
        } else {
            setImageValid(false);
        }
    }, [imgData, isOpen]);

    function returnImageData(imgData) {
        if (imgData) {
            if (imgData.success) {
                return (
                    <div className="image-container">
                        <img
                            src={GetImageSrc(imgData.img_data)}
                            style={{ maxWidth: "600px" }}
                            alt="Question Image"
                        />
                    </div>
                );
            } else {
                return (
                    <>
                        <span>Error</span>
                        <div>{imgData.error_message}</div>
                    </>
                );
            }
        } else {
            return <div>Loading...</div>;
        }
    }

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                {returnImageData(imgData)}
                <div className="modal-actions center-actions">
                    {imageValid ? (
                        <button onClick={onConfirm}>Save Image</button>
                    ) : (
                        <></>
                    )}
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default ImageModal;
