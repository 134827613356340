import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import GenerativeHome from "./components/generative/GenerativeHome";
import ProblemParent from "./components/generative/problem_console/ProblemParent";
import { updateIntercomVisibility } from "./components/shared/common/intercom/IntercomUtils";

function Generative() {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
    const location = useLocation();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        updateIntercomVisibility(false);
    }, [location]);

    if (isLoading || !isAuthenticated) {
        return <div>Loading...</div>;
    }

    return (
        <Routes>
            <Route path="/" element={<GenerativeHome set />} />
            <Route path="/generative" element={<GenerativeHome set />} />
            <Route path="/problem-review" element={<ProblemParent />} />
        </Routes>
    );
}

export default Generative;
