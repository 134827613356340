import React from "react";
import GetImageSrc from "../../shared/utils/GetImageSrc";

export const renderText = (text, image = false) => {
    if (image) {
        return (
            <div className="image-container">
                <img
                    src={text}
                    style={{ maxWidth: "500px" }}
                    alt="Question Image"
                />
            </div>
        );
    } else {
        if (text !== "") {
            return (
                <div
                    className={`${text?.toString().includes("begin{align}") ? "centered-content" : ""}`}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            );
        }
    }
};

export const MemoizedRenderText = React.memo(function MemoizedRenderText({
    text,
    image,
}) {
    return renderText(text, image);
});

export const returnFormattedProblem = (problemDict, render, reeval = false) => {
    return (
        <span style={{ display: "block" }}>
            <>
                {problemDict.image_url.startsWith("s3://") && (
                    <div style={{ height: "100%" }}>
                        <MemoizedRenderText
                            text={GetImageSrc(problemDict.image_url_encoded)}
                            image={true}
                        />
                    </div>
                )}
                <div
                    style={{
                        height: "100%",
                        marginBottom: "30px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Prompt:{" "}
                    {render ? (
                        <MemoizedRenderText text={problemDict.prompt} />
                    ) : (
                        <div>{problemDict.prompt}</div>
                    )}
                </div>
                <div
                    style={{
                        height: "100%",
                        marginBottom: "30px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Question:{" "}
                    {render ? (
                        <MemoizedRenderText text={problemDict.question} />
                    ) : (
                        <div>{problemDict.question}</div>
                    )}
                </div>
                <div
                    style={{
                        height: "100%",
                        marginBottom: "30px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    A){" "}
                    {render ? (
                        <MemoizedRenderText text={problemDict.answer_A} />
                    ) : (
                        <div>{problemDict.answer_A}</div>
                    )}
                </div>
                <div
                    style={{
                        height: "100%",
                        marginBottom: "30px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    B){" "}
                    {render ? (
                        <MemoizedRenderText text={problemDict.answer_B} />
                    ) : (
                        <div>{problemDict.answer_B}</div>
                    )}
                </div>
                <div
                    style={{
                        height: "100%",
                        marginBottom: "30px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    C){" "}
                    {render ? (
                        <MemoizedRenderText text={problemDict.answer_C} />
                    ) : (
                        <div>{problemDict.answer_C}</div>
                    )}
                </div>
                <div
                    style={{
                        height: "100%",
                        marginBottom: "30px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    D){" "}
                    {render ? (
                        <MemoizedRenderText text={problemDict.answer_D} />
                    ) : (
                        <div>{problemDict.answer_D}</div>
                    )}
                </div>
                <div
                    style={{
                        height: "100%",
                        marginBottom: "30px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Correct answer:{" "}
                    {problemDict.question_format === "radio" ? (
                        <MemoizedRenderText
                            text={problemDict.correct_radio_answer}
                        />
                    ) : (
                        <MemoizedRenderText
                            text={problemDict.correct_numeric_answer}
                        />
                    )}
                </div>
                {reeval && (
                    <div
                        style={{
                            height: "100%",
                            marginBottom: "30px",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        Reevaluation notes:
                        {
                            <MemoizedRenderText
                                text={problemDict.finalize_comments}
                            />
                        }
                    </div>
                )}
            </>
        </span>
    );
};
