import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const GenerativeLogoutButton = () => {
    const { logout } = useAuth0();
    const handleLogout = () => {
        localStorage.removeItem("isLoggedIn");
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    return (
        <button className="gen-logout-button" onClick={handleLogout}>
            Log Out
        </button>
    );
};

export default GenerativeLogoutButton;
